import React from 'react';
import PropTypes from 'prop-types';
//providers
import { useTranslation } from 'react-i18next';
//theme
import { Button, ModalOverlay, ModalContent, ModalFooter, ModalBody, Text, Heading } from '@chakra-ui/react';
//redux
import { findUserById, findCompanyById } from '../redux/selectors';
import { useSelector } from 'react-redux';


const Popup = ({ title, text, acceptButton, cancelButton, userId, businessId }) => {
  const { t } = useTranslation();

  let user = useSelector(findUserById(userId)) || {};
  let person = user.person ?? {};
  let company = useSelector(findCompanyById(businessId)) || {};

  const paramText = () => {
    let popupText = "";
    if (text === "DELETE") {
      if (person.lastName && company.name) {
        popupText = t("userlist.Delete-user-message", { name: person.firstNames + " " + person.lastName, companyname: company.name });
      }
      else {
        popupText = t("userlist.Delete-message");
      }
    }
    return popupText;
  }

  return (
    <>
      <ModalOverlay />
      <ModalContent aria-label={t(text)} mx="2" py={["3", "8"]} px={"2"} w="30em" maxWidth="100%" borderRadius="4px" textAlign="center">
        <ModalBody color="black" pt="5">
          {title &&
            <Heading as="h2" textStyle="h2" mb="4" textAlign="center">{t(title)}</Heading>
          }
          <Text fontSize="18px">
            {paramText()
              ? paramText()
              : t(text)
            }
          </Text>
        </ModalBody>
        <ModalFooter flexDirection="column">
          <Button mb={5} onClick={acceptButton.fx}>
            {t(acceptButton.text)}
          </Button>
          <Button variant="ghost" onClick={cancelButton.fx}>{t(cancelButton.text)}</Button>
        </ModalFooter>
      </ModalContent>
    </>
  );
}

Popup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  acceptButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    fx: PropTypes.func.isRequired
  }),
  cancelButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    fx: PropTypes.func.isRequired
  }),
  userId: PropTypes.string,
  businessId: PropTypes.string
}

export default Popup;
