import { extendTheme } from "@chakra-ui/react"

const darkblue = "#00325C";
const graphine = "#434343";

const theme = extendTheme({
    colors: {
        brand: {
            darkblue: darkblue,
            turquoise: "#00AECE",
            lightturquoise: "#CCEEF5",
            solnawhite: "#F6F6F1",
            graphine: "#434343",
            blue: {
                "500": darkblue,
            }
        }
    },
    fonts: {
        body: "Asap, sans-serif",
        heading: "Asap, sans-serif",
        mono: "Asap, sans-serif",
    },
    styles: {
        global: {
            body: {
                color: darkblue,
            },
            a: {
                color: darkblue,
                _hover: {
                    textDecoration: "none",
                    color: "black"
                },
            }
        }
    },
    textStyles: {
        h1: {
            fontSize: ["20px", "20px", "28px"],
            fontWeight: "700",
            lineHeight: "150%",
            textAlign: "center",
            color: darkblue,
        },
        h2: {
            fontSize: ["18px", "18px", "24px"],
            fontWeight: "700",
            lineHeight: "150%",
            color: darkblue,
            textAlign: "left",
        },
        h3: {
            fontSize: ["18px", "18px", "18px"],
            fontWeight: "700",
            lineHeight: "150%",
            textTransform: "uppercase",
            color: darkblue,
        },
        badge: {
            color: "white",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: "12px",
            lineHeight: "18px",
        },
        breadcrumb: {
            color: "black",
            fontSize: "12px",
            lineHeight: "18px",
            textAlign: "left",
        },
        smalltext: {
            color: "black",
            fontSize: "14px",
            lineHeight: "150%",
        },
        errortext: {
            pl: "4",
            fontSize: "14px",
            color: "red.600",
            fontWeight: "700"
        },
        infomsg: {
            fontSize: "14px",
            background: "#CCEFF5",
            color: "#434343",
            borderLeft: "4px solid" + darkblue,
            p: ["3", "4"]
        },
        errormsg: {
            fontSize: "14px",
            background: "#FED7D7",
            color: "#434343",
            borderLeft: "4px solid",
            borderColor: "red.600",
            p: ["3", "4"]
        },
        successmsg: {
            fontSize: "14px",
            background: "#C6F6D5",
            color: "#434343",
            borderLeft: "4px solid",
            borderColor: "green.600",
            p: ["3", "4"]
        }
    },
    components: {
        Button: {
            baseStyle: {
                borderWidth: "2px",
                borderRadius: "4px",
                _focus: {
                    boxShadow: "none",
                },
                _disabled: {
                    opacity: "1",
                    borderColor: "gray.400",
                    bg: "transparent",
                    color: "gray.400",
                },
                _hover: {
                    _disabled: {
                        bg: "transparent"
                    }
                }
            },
            variants: {
                solid: { // blue outline button
                    borderColor: darkblue,
                    bg: "transparent",
                    color: darkblue,
                    _hover: {
                        borderColor: darkblue,
                        bg: darkblue,
                        color: "white",
                        _disabled: {
                            bg: "transparent",
                        }
                    },
                },
                red: { // red solid button
                    bg: "red.600",
                    border: "0px",
                    color: "white",
                    _hover: {
                        bg: "red.700",
                        _disabled: {
                            bg: "gray.400",
                            color: "white",
                        }
                    },
                    _disabled: {
                        bg: "gray.400",
                        color: "white",
                    }
                },
                filled: {  // filled blue button
                    bg: darkblue,
                    border: "0px",
                    color: "white",
                    _hover: {
                        bg: "black",
                        _disabled: {
                            bg: "gray.400",
                            color: "white",
                        }
                    },
                    _disabled: {
                        bg: "gray.400",
                        color: "white",
                    }
                },
                ghost: { // no border button
                    borderColor: "transparent",
                    bg: "transparent",
                    _hover: {
                        border: "2px",
                        borderColor: darkblue,
                        bg: darkblue,
                        color: "white"
                    }
                },
                redoutline: {
                    borderColor: "red.600",
                    color: "red.600",
                    _hover: {
                        bg: "red.700",
                        borderColor: "red.700",
                        color: "white",
                    },
                },
                greenoutline: {
                    borderColor: "green.600",
                    color: "green.600",
                    _hover: {
                        bg: "green.600",
                        color: "white",
                    },
                }
            }
        },
        Input: {
            variants: {
                outline: {
                    field: {
                        borderColor: graphine,
                        borderWidth: "1px",
                        borderRadius: "2px",
                        height: "40px",
                        mb: "1",
                        _focus: {
                            borderColor: darkblue,
                            boxShadow: "0 0 0 1px " + darkblue,
                        },
                        _hover: {
                            borderColor: "#718096",
                        },
                        _placeholder: {
                            color: "#718096",
                        }
                    }
                }
            },
        },
        Select: {
            variants: {
                outline: {
                    field: {
                        borderRadius: "2px",
                        borderColor: graphine,
                        _focus: {
                            borderColor: darkblue,
                            boxShadow: "0 0 0 1px " + darkblue,
                        },
                        _hover: {
                            borderColor: "#718096",
                        }
                    }
                }
            }
        }

    },
});

export default theme;