import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
//providers
import { useTranslation } from 'react-i18next';
//theme
import { Box, Flex, ScaleFade } from '@chakra-ui/react';
import { ReactComponent as InfoIcon } from '../images/infoicon.svg';
import { ReactComponent as ErrorIcon } from '../images/erroricon.svg';
import { ReactComponent as SuccessIcon } from '../images/successicon.svg';
import { getErrorMessages } from '../redux/helpers';



const MessageBox = ({ type, message, isOpen, redirect, errorObject }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [open, setOpen] = useState(true);

    useEffect(() => {
        if (isOpen) {
            const timer = setTimeout(() => {
                setOpen(false);
                if (type === "success" && redirect) {
                    history.push(redirect);
                }
            }, 3000);
            return () => clearTimeout(timer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, type]);


    const errorMessage = (type === "error" && message) ? message : getErrorMessages(errorObject, t);


    return (
        <>
            { type === "error" && isOpen &&
                <Flex width="100%" mt="4" textStyle="errormsg" alignItems={["flex-start", "center"]} mb={["6", "6"]} >
                    <Box flexShrink="0" > <ErrorIcon /></Box>
                    <Box ml={["3", "4"]}>{errorMessage ?? t('syserror')}</Box>
                </Flex>
            }
            { type === "info" &&
                <Flex width="100%" mt="4" textStyle="infomsg" alignItems={["flex-start", "center"]} mb={["6", "6"]} >
                    <Box flexShrink="0" > <InfoIcon /></Box>
                    <Box ml={["3", "4"]}>{message}</Box>
                </Flex>
            }
            { type === "success" &&
                <ScaleFade unmountOnExit="true" width="100%" mb={["6", "6"]} in={isOpen && open} reverse="true">
                    <Flex mt="4" textStyle="successmsg" alignItems={["flex-start", "center"]} >
                        <Box flexShrink="0" > <SuccessIcon /></Box>
                        <Box ml={["3", "4"]}>{message}</Box>
                    </Flex>
                </ScaleFade>
            }
        </>
    );
}

MessageBox.propTypes = {
    type: PropTypes.string.isRequired,
    message: PropTypes.string,
    isOpen: PropTypes.bool,
    redirect: PropTypes.string,
    errorObject: PropTypes.object,
}

export default MessageBox;