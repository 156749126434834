
let API = process.env.REACT_APP_API_URL;
/*if (process.env.NODE_ENV === "production") {
    API = REACT_APP_PROD_API_URL;
}
if (process.env.NODE_ENV === "staging") {
    API = REACT_APP_TEST_API_URL;
}
else {
    API = REACT_APP_DEV_API_URL;
}*/

export const APIBASE = `${API}/admin`;
export const API_PERSON = `${APIBASE}/person/v1`;
export const API_PR = `${APIBASE}/purchase_rights/v1`;
export const API_APPROVALS = `${APIBASE}/approvals/v1`;
export const API_ADMIN = `${APIBASE}/restricted/v1`;


export const fetchPostAPI = async (url, payload, rejectWithValue, method, responseType) => {
    let response = {};
    const access_token = sessionStorage.getItem("access_token");
    const token_type = sessionStorage.getItem("token_type");

    if (method === "get") {
        if (responseType === "blob" || responseType === "csv") {
            response = await fetch(url, {
                headers:
                {
                    "Content-Type": "application/octet-stream",
                    "Authorization": `${token_type} ${access_token}`,
                },
                responseType: 'arraybuffer',
                dataType: 'blob'
            })
        } 
        else {
            response = await fetch(url, { headers: { "Authorization": token_type + " " + access_token } });
        }
    } else {
        response = await fetch(url, {
            method: method ?? 'post',
            body: JSON.stringify(payload),
            headers: { "content-type": "application/json", "Authorization": token_type + " " + access_token }
        });
    }
    if (!response.ok) {
        //Client error responses
        if (response.status > 399 && response.status < 499) {
            return response.json();
        }
        else {
            return rejectWithValue({ statusText: response.statusText, status: response.status });
        }
    }
    if (responseType === "text") {
        const text = await response.text();
        return text;
    }
    if (responseType === "blob") {
        const url = await response.arrayBuffer().then(response => {
            const file = new Blob([response], { type: 'application/pdf' });
            const fileUrl = URL.createObjectURL(file);
            return fileUrl;
        });
        return url;
    }
    if (responseType === "csv") {
        const filename = response.headers.get('content-disposition').split('filename=')[1].split(';')[0];
        const url = await response.arrayBuffer().then(response => {
            const file = new Blob([response], { type: 'text/csv' });
            const fileUrl = URL.createObjectURL(file);
            return fileUrl;
        });
        const stuff = {url: url, filename: filename};
        return stuff;
    }
    const json = await response.json();
    return json;
}


