import React, { useState, useEffect } from 'react';
import { useParams, useHistory, Redirect } from "react-router-dom";
//providers
import { useTranslation } from 'react-i18next';
//theme
import { Box, Heading, Flex, Container, Button, useDisclosure, Modal } from '@chakra-ui/react';
import { ReactComponent as PlusIcon } from '../images/plusicon.svg';
//redux
import { findCompanyById, getCompanyLoading, getCompanyErrors, getSuperAdmin, getAuthExpiring } from '../redux/selectors';
import { useDispatch, useSelector } from "react-redux";
import { deleteActor, deleteSuperadminActor, selectCompany } from '../redux/companiesSlice';
import { refreshAuth } from '../redux/authUserSlice';
//compontents
import Popup from './Popup';
import BreadcrumbNav from './BreadcrumbNav';
import UserBox from './UserBox';
import MessageBox from './MessageBox';
import CompanyBox from './CompanyBox';
import { DRAFT, WAITING_APPROVAL, SUPERADMIN } from '../redux/helpers';


const UserList = () => {
  const { t, ready } = useTranslation();
  const { businessId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const authExpiring = useSelector(getAuthExpiring());

  //get company details from BackEnd
  useEffect(() => {
    if (authExpiring) {
      dispatch(refreshAuth());
    }
    if (businessId) {
      dispatch(selectCompany({ businessId: businessId.trim() }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, businessId, authExpiring]);

  const company = useSelector(findCompanyById(businessId));
  const loading = useSelector(getCompanyLoading);
  const companyErrors = useSelector(getCompanyErrors);
  const superAdmin = useSelector(getSuperAdmin);

  const needsAdminRedirect = company && (company.state === DRAFT || (company.state === WAITING_APPROVAL && (company.users && !company.users.length)));

  let users = company ? company.users ?? [] : [];

  if (users.length) {
    users = [...users].sort((a, b) => a.person && a.person.lastName.localeCompare(b.person.lastName));
  }


  //const approvals = useSelector(getApprovals);

  const [modalid, setModalid] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleUserPopup = (modalid) => {
    setModalid(modalid);
    onOpen();
  }

  const handleDeleteUser = () => {
    const user = users.find(user => user.id === modalid);
    if (user) {
      if (user.role === SUPERADMIN) {
        dispatch(deleteSuperadminActor({ userId: modalid, businessId: businessId }));
      }
      else {
        dispatch(deleteActor({ userId: modalid, businessId: businessId }));
      }
    }
    else {
      dispatch(deleteActor({ userId: modalid, businessId: businessId }));
    }
    onClose();
  }

  const breadcrumbLinks = [
    { url: "/", text: "breadcrumb.Company-selection" },
    { url: `/company/${businessId}`, text: "breadcrumb.Company" }
  ]

  return (
    <>
      { ready &&
        <>
          <Container as="main" maxW="55em" pb="9%">
            <Box>
              {(!companyErrors && !superAdmin && needsAdminRedirect) &&
                <Redirect to={`/adduser/${businessId}`} />
              }
              <BreadcrumbNav links={breadcrumbLinks} />
              {company
                ?
                <Box>
                  {(!loading && companyErrors) &&
                    <MessageBox isOpen={true} type="error" errorObject={companyErrors} />
                  }
                  <CompanyBox company={company} />
                  <Flex mb={["25px", "42px"]} justifyContent="space-between" flexWrap="wrap">
                    <Heading as="h1" textStyle="h1" textAlign="left">
                      {t('userlist.Purchase-right-holders')}
                    </Heading>
                    <Button ml="auto" mt={["6", "0"]} onClick={() => { history.push(`/adduser/${businessId}`) }}>
                      <Box display="inline-block" ml="-3" mr="1"><PlusIcon className="graystroke" /></Box>
                      {t('userlist.Add-user')}
                    </Button>
                  </Flex>
                  {users.length > 0 &&
                    <Box>
                      {
                        Object.keys(users).map(key => (
                          <UserBox key={key} user={users[key]} handleUserPopup={handleUserPopup} businessId={businessId} />
                        ))
                      }
                    </Box>
                  }
                </Box>
                :
                <Box textAlign="center" mt="10%">
                  {(!loading && companyErrors) &&
                    <MessageBox isOpen={true} type="error" errorObject={companyErrors} />
                  }
                  {!loading &&
                    <>
                      <Heading as="h3" textStyle="h3" mb="5">{t("companylist.No-company-found")}</Heading>
                      <Button onClick={() => history.push(`/`)}>{t("login.to-frontpage")}</Button>
                    </>
                  }
                </Box>
              }
            </Box>
          </Container >
          <Modal modalid={modalid} isOpen={isOpen} onClose={onClose} key={modalid}>
            <Popup userId={modalid} businessId={businessId} text={"DELETE"} acceptButton={{ text: 'userlist.Delete-user', fx: handleDeleteUser }} cancelButton={{ text: 'userlist.Cancel', fx: onClose }} />
          </Modal>
        </>
      }
    </>
  );
}

export default UserList;

