import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
//providers
import { useTranslation } from 'react-i18next';
import { fetchAuthLink } from '../redux/authUserSlice';
import { useDispatch, useSelector } from "react-redux";
import { getLoginLink, getAuthUser } from "../redux/selectors";
//theme
import { Box, Container, Image, Flex, Button, Heading } from '@chakra-ui/react';
import logoSignicat from '../images/logo-signicat2.svg';
import { getErrorMessages } from '../redux/helpers';


const Login = ({ error }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loginLink = useSelector(getLoginLink);
  const authUser = useSelector(getAuthUser());

  const goLogin = () => {
    if (loginLink) {
      // add lang param to login link
      const lang = localStorage.getItem('i18nextLng');
      window.location = loginLink + "&ui_locales=" + lang;
    }
  }

  useEffect(() => {
    if (!authUser && !loginLink) {
      dispatch(fetchAuthLink());
    }
  }, [dispatch, loginLink, authUser]);


  return (
    <Container as="main" maxWidth="100%">
      <Flex alignContent="center" justifyContent="center" alignItems="center" textAlign="center" minHeight="85vh">
        <Box bg="brand.solnawhite" pt={["10", "16"]} pb={["10", "10"]} px={"3"} w="28em" maxWidth="100%" borderRadius="4px" >
          <Heading as="h1" textStyle="h1" mb="10">{t('login.Login')}</Heading>
          <Button
            onClick={() => { goLogin() }}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('login.E-identification')}
          </Button>
          <Box pr="5" textStyle="errortext" mt="2">{getErrorMessages(error, t)}</Box>
          <Image src={logoSignicat} alt="Signicat logo" w="101px" mt="5" mr="auto" ml="auto" />
          <Box px="5" mt="8" >{t("login.Accepted-terms")}</Box>
          <Box mt="2">
            <a href={t("login.Terms-link")} rel="noreferrer noopener" target="_blank">{t("login.Terms")} </a>
           | <a href={t("login.Privacy-statement-link")} rel="noreferrer noopener" target="_blank">{t("login.Data-protection")}</a></Box>
        </Box>
      </Flex>
    </Container>
  );
};

Login.propTypes = {
  error: PropTypes.array
}

export default Login;
