import React from 'react';
import { useHistory } from "react-router-dom";
//providers
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthUser } from '../redux/selectors';
//theme
import { Container, Image, Menu, MenuItem, MenuList, MenuButton, Flex, Box, Link } from '@chakra-ui/react';
import logo from '../images/Svea_RGB.svg';
import { ReactComponent as ChevrondownIcon } from '../images/chevrondownicon.svg';
import { ReactComponent as LogoutIcon } from '../images/logouticon.svg';
import i18n from '../i18n.js';
import { logout } from '../redux/reducers';

function Header() {
    const { t, ready } = useTranslation();
    const authUser = useSelector(getAuthUser());
    const history = useHistory();
    const dispatch = useDispatch();

    const changeLanguage = (lng) => {
        localStorage.setItem('loc_id', lng);
        i18n.changeLanguage(lng);
    };
    const lng = new URLSearchParams(window.location.search).get('language');
    if (lng) {
        if (lng === 'fi' || lng === 'sv' || lng === 'en') {
            changeLanguage(lng);
        }
    }

    return (
        <>
            { ready &&
                <Flex as="header" role="banner" bg="white" borderBottom="0px" borderColor="brand.solnawhite" height={["72px", "80px"]} boxShadow="0px 2px 8px rgba(0, 0, 0, 0.1)">
                    <Container maxWidth="100%" mx={["8px", "80px"]} >
                        <Flex as="nav" role="navigation" justifyContent="space-between">
                            <Link role="link" onClick={() => history.push('/')} aria-label={t("login.to-frontpage")} className="svealogo"><Image src={logo} alt="svea logo" w={["60px", "90px"]} mt="23px" mb="23px" /></Link>
                            <Flex flexBasis="100%" justifyContent="flex-end">
                                {
                                    authUser &&
                                    <Flex as="a" aria-label={t("login.Logout")} className="blackhover" alignItems="center" ml="3" onClick={() => { dispatch(logout()) }}>
                                        <Box display={["none", "inline-block"]} cursor="pointer" mr="3" >{t('login.Logout')}</Box>
                                        <Box display="inline-block" mr="3" cursor="pointer" ><LogoutIcon alt="logout" /></Box>
                                    </Flex>
                                }
                                <Flex justifyContent="flex-end" flexBasis={["50px", "100px"]} flexShrink="1">
                                    <Menu>
                                        <MenuButton ml={["4", "4", "auto"]} className="blackhover">
                                            <Flex as="a" textTransform="uppercase" alignItems="center">
                                                <Box mr={["3", "5"]}>{i18n.language}</Box><ChevrondownIcon />
                                            </Flex>
                                        </MenuButton>
                                        <MenuList>
                                            {Object.keys(i18n.services.resourceStore.data).map(key => (
                                                key !== i18n.language &&
                                                <MenuItem textTransform="uppercase" key={key} onClick={() => changeLanguage(key)}>{key}</MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Container>
                </Flex >
            }
        </>
    );
}

export default Header;