import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-number-input';
//providers
import { useTranslation } from 'react-i18next';
//theme
import { Box, Input, FormLabel, FormControl, Select } from '@chakra-ui/react';
import 'react-phone-number-input/style.css'

export const FormElement = forwardRef(({ name, type, desc, options, isDisabled, prefix, state, handleInputChange, readOnly, placeholder }, ref) => {
    const { t } = useTranslation();
    const defaultValue = prefix ? state[prefix][name] : state[name];
    const autoComplete = "off";

    const handlePhoneChange = (props) => {
        handleInputChange({ target: { name: "phoneNumber", value: props } });
    }

    const disabled = isDisabled || readOnly;

    const approvalPlaceholder = placeholder ? placeholder : "";


    return (
        <FormControl className="formitem" id={name} mb="4" maxWidth={(type === "select") && ["100%", "50%"]} >
            {type === "approvalInput"
                ? <Input type="email" name={name} placeholder={approvalPlaceholder} autoComplete={autoComplete}
                    value={defaultValue.email ?? ""} onChange={(e) => { handleInputChange(e) }}
                    isInvalid={state.errors[name].length} disabled={disabled} data-prefix={prefix} ref={ref} />
                :
                <>
                    <FormLabel >{t("form." + name)}</FormLabel>
                    { type === "select"
                        ? <Select maxWidth={["70%", "265px"]} name={name} onChange={(e) => handleInputChange(e)} value={defaultValue}
                            isInvalid={state.errors[name].length} disabled={disabled} data-prefix={prefix} ref={ref}>
                            {
                                options.map((item, key) => (
                                    <option key={key} value={item}>{t(item)}</option>
                                ))
                            }
                        </Select>
                        : type === "tel"
                            ?
                            <PhoneInput international data-invalid={state.errors[name].length ? "invalid" : ""} placeholder={t("form." + name)}
                                autoComplete={autoComplete} name={name} value={defaultValue} disabled={disabled} onChange={(props) => { handlePhoneChange(props) }} defaultCountry="FI" />
                            : <Input type={type} name={name} placeholder={t("form." + name)} autoComplete={autoComplete}
                                value={defaultValue} onChange={(e) => { handleInputChange(e) }}
                                isInvalid={state.errors[name].length} disabled={disabled} data-prefix={prefix} ref={ref} />
                    }
                </>
            }
            <Box textStyle="errortext"> {state.errors[name]} </Box>
            { desc &&
                <Box textStyle="smalltext" color="brand.graphine"> {t(desc)} </Box>
            }
        </FormControl>
    );

});

FormElement.propTypes = {
    name: PropTypes.string,
    type: PropTypes.string,
    desc: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    isDisabled: PropTypes.bool,
    prefix: PropTypes.string,
    state: PropTypes.shape({
        newUser: PropTypes.bool,
        businessId: PropTypes.string,
        role: PropTypes.string,
        person: PropTypes.shape({
            socialSecurityNumber: PropTypes.string,
            identifierCountryCode: PropTypes.string,
            firstNames: PropTypes.string,
            lastName: PropTypes.string,
        }),
        phoneNumber: PropTypes.string,
        email: PropTypes.string,
        purchaseRight: PropTypes.shape({
            maxAmount: PropTypes.string,
            currencyCode: PropTypes.string,
            period: PropTypes.string,
        }),
        errors: PropTypes.shape({
            role: PropTypes.string,
            firstNames: PropTypes.string,
            lastName: PropTypes.string,
            socialSecurityNumber: PropTypes.string,
            identifierCountryCode: PropTypes.string,
            phoneNumber: PropTypes.string,
            email: PropTypes.string,
            maxAmount: PropTypes.string,
            currencyCode: PropTypes.string,
            period: PropTypes.string,
        })
    }),
    handleInputChange: PropTypes.func,
    readOnly: PropTypes.bool,
    placeholder: PropTypes.string
}

export default FormElement;