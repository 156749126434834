import { createReducer, createAction } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import approvals from './approvalsSlice';
import authUser from './authUserSlice';
import companies from './companiesSlice';


const allReducers = combineReducers({ companies, approvals, authUser });

export const logout = createAction('crossSlice/logout');

//reset state when logout
const crossSliceReducer = createReducer({}, {
    [logout]: (state, action) => {
        state.companies = { loading: "", error: "", companies: [] };
        state.approvals = { loading: "", error: "", companies: [] };
        state.authUser = { loading: "", error: "", user: null, activationCompanies: null};
        sessionStorage.removeItem('access_token');
        sessionStorage.removeItem('token_type');
    }
});

const rootReducer = (state, action) => {
    const intermediateState = allReducers(state, action);
    const finalState = crossSliceReducer(intermediateState, action);
    return finalState;
};

export default rootReducer;