
//roles
export const BUYER = "BUYER";
export const ADMIN = "ADMIN";
export const SUPERADMIN = "SUPERADMIN";

//status user WAITING_APPROVAL, ACTIVE, DISABLED, FROZEN, DELETED
//status company DRAFT, WAITING_APPROVAL, ACTIVE, DISABLED, FROZEN, DELETED
export const WAITING_APPROVAL = "WAITING_APPROVAL";
export const ACTIVE = "ACTIVE";
export const DISABLED = "DISABLED";
export const FROZEN = "FROZEN";
export const DELETED = "DELETED";
export const DRAFT = "DRAFT";

export const validateInput = (property, value, countryCode, options) => {
    switch (property) {
        case 'firstNames': // min 2 max 100
            const rf = /^[\p{L} ,.'-]{2,}$/u;
            return rf.test(value);
        case 'lastName': //min 2 max 100
            //const rn = /^[0-9A-Za-zÀ-ÖØ-öø-ÿ-.,'\s]{2,100}$/;
            const rn = /^[\p{L} ,.'-]{2,}$/u;
            return rn.test(value);
        case 'phoneNumber': //min 6 max 30
            const rp = /^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]{6,30}$/g;
            return rp.test(value);
        case 'email':
            // eslint-disable-next-line
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(value);
        case 'businessId':
            const rb = /^(\d\d\d\d\d\d\d?-?\d)$/;
            return rb.test(value);
        case 'maxAmount':
            const rl = /^\+?(0|[0-9\s]{0,30})$/;
            return value.length ? rl.test(value) : true;
        case 'role':
            return options.includes(value);
        case 'identifierCountryCode':
            return options.includes(value);
        case 'currencyCode':
            return options.includes(value);
        case 'period':
            return options.includes(value);
        case 'socialSecurityNumber':
            const rs = {
                FI: /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d-|[012]\dA)\d{3}[\dA-Z]$/,
                SV: /^(19|20)?(\d{6}([-+]|\s)\d{4}|(?!19|20)\d{10})$/,
                NO: /^(0[1-9]|[1-2][0-9]|31(?!(?:0[2469]|11))|30(?!02))(0[1-9]|1[0-2])\d{7}/,
                EE: /^[1-6][0-9]{2}[0-1][0-9][0-9]{2}[0-9]{4}$/,
            };
            return rs[countryCode] ? rs[countryCode].test(value) : false;
        default:
            return true;
    }
}

export const getErrorMessages = (errors, t) => {

    if (errors && errors instanceof Array) {
        const errorList = errors.map(errorObject => {
            return getErrorMessage(errorObject, t);
        })
        return errorList.join();
    } else {
        return getErrorMessage(errors, t);
    }
};

const getErrorMessage = (errorObject, t) => {
    if (errorObject) {
        if (errorObject.code) {
            if (errorObject.code.indexOf(",") !== -1) {
                const codes = errorObject.code.split(",");
                let errorMessages = "";
                codes.forEach((code) => {
                    errorMessages += t(code) + ". ";
                });
                return errorMessages;
            } else {
                return t(errorObject.code);
            }
        } else {
            return t(errorObject.statusText) ?? t('syserror');
        }
    } else {
        return "";
    }
};