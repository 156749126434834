import React, { createRef, useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
//providers
import { useTranslation } from 'react-i18next';
//theme
import { Box, Heading, Flex, Container, Button } from '@chakra-ui/react';
//redux
import { getAuthUser, findCompanyById, getUsersOfCompany, getCompanyLoading, getSuperAdmin, getCompanyErrors, findUserById, getAuthExpiring, findNewUserBySSN, findUserBySaved } from '../redux/selectors';
import { useDispatch, useSelector } from "react-redux";
import { validateInput, ADMIN, BUYER, SUPERADMIN, WAITING_APPROVAL, ACTIVE, DRAFT, FROZEN } from '../redux/helpers';
import { saveActor, saveSuperadminActor, selectCompany } from '../redux/companiesSlice';
import { refreshAuth } from '../redux/authUserSlice';
//compontents
import UserFormHeader from './UserFormHeader';
import { FormElement } from './FormElement';
import Approval from './Approval';
import MessageBox from './MessageBox';
import { fetchAuthUserCompanies } from '../redux/authUserSlice';
import BreadcrumbNav from './BreadcrumbNav';


const UserForm = () => {
    const { userId, businessId } = useParams();
    const isNewUser = userId ? false : true;

    const { t, ready } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const authUser = useSelector(getAuthUser());
    const loading = useSelector(getCompanyLoading);
    const superAdmin = useSelector(getSuperAdmin);
    const companyErrors = useSelector(getCompanyErrors);
    const company = useSelector(findCompanyById(businessId));
    const companyUsers = useSelector(getUsersOfCompany(businessId));
    const authExpiring = useSelector(getAuthExpiring());
    const [userReady, setUserReady] = useState(false);
    const [userSearch, setUserSearch] = useState(false);
    const [hide, setHide] = useState(false);

    const [show, showError] = useState(true);

    useEffect(() => {
        if (authExpiring) {
            dispatch(refreshAuth());
        }
        if (!company) {
            //we came from a user link no company loaded
            dispatch(selectCompany({ businessId: businessId }));
        }
        //clear errors at loading user
        //showError(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authExpiring]);

    const id = isNewUser ? "NEW" : userId;
    const findUser = useSelector(findUserById(id, businessId));
    const userSaved = useSelector(findUserBySaved(businessId));
    const authSSN = authUser ? authUser.socialSecurityNumber : "";
    const savedAdmin = useSelector(findNewUserBySSN(authSSN, businessId));

    const getUserData = () => {
        let userData = findUser ?? { person: {}, purchaseRight: {}, id: "NEW" };
        // new user
        if (!findUser) {
            if (company && (company.state === DRAFT || company.state === WAITING_APPROVAL)) {
                // company has not been approved
                // copy authUser info if not superAdmin logged in
                if (!superAdmin) {
                    userData.person = {
                        firstNames: authUser.firstNames,
                        lastName: authUser.lastName,
                        identifierCountryCode: authUser.nationality,
                        socialSecurityNumber: authUser.socialSecurityNumber
                    }
                };
                if (savedAdmin) {
                    userData.phoneNumber = savedAdmin.phoneNumber;
                    userData.email = savedAdmin.email;
                }
            }
        }
        return userData;
    }

    let user = getUserData();

    let waitingAdmin = (user.state === WAITING_APPROVAL);
    const needsAdmin = company && (company.state === DRAFT || company.state === WAITING_APPROVAL);

    const initialForm = Object.freeze({
        newUser: isNewUser,
        businessId: businessId ? businessId : "",
        role: user && user.role ? user.role : needsAdmin ? ADMIN : BUYER,
        person: {
            socialSecurityNumber: user.person && user.person.socialSecurityNumber ? user.person.socialSecurityNumber : "",
            identifierCountryCode: user.person && user.person.identifierCountryCode ? user.person.identifierCountryCode : "FI",
            firstNames: user.person && user.person.firstNames ? user.person.firstNames : "",
            lastName: user.person && user.person.lastName ? user.person.lastName : ""
        },
        phoneNumber: user && user.phoneNumber ? user.phoneNumber : "",
        email: user && user.email ? user.email : "",
        purchaseRight: {
            maxAmount: user && user.purchaseRight && user.purchaseRight.maxAmount ? user.purchaseRight.maxAmount : "",
            currencyCode: user && user.purchaseRight && user.purchaseRight.currencyCode ? user.purchaseRight.currencyCode : "EUR",
            period: user && user.purchaseRight && user.purchaseRight.period ? user.purchaseRight.period : "MONTHLY"
        },
        errors: {
            role: "",
            firstNames: "",
            lastName: "",
            socialSecurityNumber: "",
            identifierCountryCode: "",
            phoneNumber: "",
            email: "",
            maxAmount: "",
            currencyCode: "",
            period: ""
        }
    });


    const options = {
        role: needsAdmin ? [ADMIN] : superAdmin && companyUsers && companyUsers.find(user => user.role === SUPERADMIN) ? [BUYER, ADMIN, SUPERADMIN] : [BUYER, ADMIN],
        identifierCountryCode: ["FI", "SV", "NO", "EE"],
        currencyCode: ["EUR"],
        period: ["WEEKLY", "MONTHLY"]
    }

    const SSN = createRef();
    const CC = createRef();

    const [formData, updateFormData] = useState(initialForm);

    const handleInputChange = (e) => {
        let property = e.target.name;
        let value = e.target.value;
        const prefix = Object.keys(formData.person).includes(property) ? "person" : Object.keys(formData.purchaseRight).includes(property) ? "purchaseRight" : "";
        let key = prefix ? prefix : property;
        let update = prefix ? { ...formData[prefix], [property]: value } : value;

        let allErrors = getErrors({ [property]: value });

        //update state
        updateFormData({
            ...formData,
            [key]: update,
            errors: {
                ...formData.errors,
                ...allErrors,
            }
        });
    }

    const handleSSNChange = (e) => {
        let valueSSN = SSN.current.value;
        let valueCC = CC.current.value;

        const validCountryCode = validateInput("identifierCountryCode", valueCC, valueCC, options.identifierCountryCode);
        const validSocialSecurityNumber = validateInput("socialSecurityNumber", valueSSN, valueCC, options.identifierCountryCode);

        const errors = {
            socialSecurityNumber: validSocialSecurityNumber ? "" : t("userform.invalid-input", { inputname: t("form.socialSecurityNumber").toLowerCase() }),
            identifierCountryCode: validCountryCode ? "" : t("userform.invalid-input", { inputname: t("form.countryCode").toLowerCase() })
        }

        updateFormData({
            ...formData,
            person: {
                ...formData.person,
                identifierCountryCode: valueCC,
                socialSecurityNumber: valueSSN,
            },
            errors: {
                ...formData.errors,
                ...errors,
            }
        });

    }

    const getErrors = (properties) => {
        let allErrors = {};
        const personFields = Object.keys(formData.person);
        const purchaseFields = Object.keys(formData.purchaseRight);
        const countryCode = formData.person.identifierCountryCode;
        const checkArray = properties ? Object.keys(properties) : Object.keys(formData.errors);

        checkArray.forEach((property) => {
            const prefix = personFields.includes(property) ? "person" : purchaseFields.includes(property) ? "purchaseRight" : "";
            const propertyValue = properties ? properties[property] : null;
            const formValue = prefix ? formData[prefix][property] : formData[property];
            const value = propertyValue !== null ? propertyValue : formValue;
            const error = validateInput(property, value, countryCode, options[property]) ? "" : t("userform.invalid-input", { inputname: t("form." + property).toLowerCase() });

            allErrors = { ...allErrors, [property]: error };
        });

        return allErrors;
    }

    const resetForm = () => {
        updateFormData(initialForm);
    }

    
    if (!userReady) {
        if (initialForm.person.socialSecurityNumber.length > 0) {
            resetForm();
            setUserReady(true);
        }
        else {
            if (!userSearch) {
                setUserSearch(true);
                dispatch(fetchAuthUserCompanies());
            }
        }
    }

    const submitForm = () => {
        console.log("Submitting...");
        let formHasErrors = false;
        const allErrors = getErrors();
        if (Object.values(allErrors).find(value => value !== "")) {
            formHasErrors = true;
            updateFormData({
                ...formData,
                errors: {
                    ...formData.errors,
                    ...allErrors
                }
            });
        }

        if (!formErrors && !formHasErrors) {
            //removing properties
            let { errors, ...userUpdate } = formData;
            let updatedUser = { ...user, ...userUpdate };
            let actor = { newUser: updatedUser, userId: "NEW", businessId: company.businessId };
            if (!isNewUser) {
                actor.userId = userId;
            }

            if (updatedUser.role === SUPERADMIN) {
                dispatch(saveSuperadminActor(actor));
            }
            else {
                if (needsAdmin) {
                    setHide(true);
                }
                dispatch(saveActor(actor));
            }

            if (!loading) {
                showError(true);
            }
        }
    }
    const breadcrumbLinks = [
        { url: "/", text: "breadcrumb.Company-selection" },
        { url: `/company/${businessId}`, text: "breadcrumb.Company" },
        { url: isNewUser ? `/adduser/${businessId}` : `/user/${businessId}/${userId}`, text: "breadcrumb.User-info" }
    ];

    //we loaded company later
    if (userId && company && initialForm.person.socialSecurityNumber !== formData.person.socialSecurityNumber) {
        updateFormData(initialForm);
    }

    //const savedUser = useSelector(findNewUserBySSN(formData.person.socialSecurityNumber, businessId));
    if (isNewUser && savedAdmin) {
        user = savedAdmin;
    }

    if (savedAdmin && needsAdmin) {
        waitingAdmin = true;
    }

    const readOnly = waitingAdmin || (user && user.state === FROZEN);
    const noPurchaseRight = (user && user.state !== ACTIVE && !isNewUser) ? true : false;
    const formErrors = (Object.values(formData.errors).find(value => value !== "")) ? true : false;

    return (
        <>
            {ready &&
                <Container as="main" maxW="55em" pb="9%">
                    <BreadcrumbNav links={breadcrumbLinks} />
                    {(!company && companyErrors) &&
                        <Box mt="10%">
                            <MessageBox isOpen={true} type={"error"} errorObject={companyErrors} />
                        </Box>
                    }
                    {company && user
                        ? <Box>
                            <UserFormHeader businessId={businessId} user={isNewUser ? null : user} loading={loading} showError={showError} hide={hide} />
                            <Box color="black">
                                {!hide &&
                                <Box autoComplete="off" className={waitingAdmin ? "form waiting" : "form"}>
                                    <FormElement type="select" name="role" isDisabled={isNewUser ? false : true} options={options.role} state={formData} handleInputChange={handleInputChange} readOnly={waitingAdmin} />
                                    <Heading as="h2" textStyle="h3" mt="10" mb="3">{t("userform.Personal-info")}</Heading>
                                    <Flex flexDirection={["column", "row"]}>
                                        <FormElement name="firstNames" prefix="person" state={formData} handleInputChange={handleInputChange} readOnly={readOnly} />
                                        <FormElement name="lastName" prefix="person" state={formData} handleInputChange={handleInputChange} readOnly={readOnly} />
                                    </Flex>
                                    <Flex flexDirection={["column", "row"]}>
                                        {isNewUser
                                            && <>
                                                <FormElement name="socialSecurityNumber" prefix="person" state={formData} handleInputChange={handleSSNChange} ref={SSN} readOnly={readOnly || needsAdmin} />
                                                <FormElement type="select" name="identifierCountryCode" options={options.identifierCountryCode} prefix="person"
                                                    state={formData} handleInputChange={handleSSNChange} ref={CC} readOnly={readOnly} />
                                            </>
                                        }
                                    </Flex>
                                    <Heading as="h2" textStyle="h3" mt="6" mb="3">{t("userform.Contacts")}</Heading>
                                    <Flex flexDirection={["column", "row"]}>
                                        <FormElement name="phoneNumber" type="tel" state={formData} handleInputChange={handleInputChange} readOnly={readOnly} />
                                        <FormElement name="email" type="email" state={formData} handleInputChange={handleInputChange} readOnly={readOnly} />
                                    </Flex>
                                    {(user.role === BUYER || formData.role === BUYER) &&
                                        <>
                                            <Heading as="h2" textStyle="h3" mt="6" mb="3">{t("userform.Purchase-right")}</Heading>
                                            <Flex flexDirection={["column", "row"]} className={noPurchaseRight}>
                                                <FormElement name="maxAmount" prefix="purchaseRight" type="text" desc={"userform.Leave-empty-no-limit"}
                                                    isDisabled={noPurchaseRight} state={formData} handleInputChange={handleInputChange} readOnly={readOnly} />
                                                <FormElement type="select" name="currencyCode" prefix="purchaseRight" options={options.currencyCode}
                                                    isDisabled={noPurchaseRight} state={formData} handleInputChange={handleInputChange} readOnly={readOnly} />
                                                <FormElement type="select" name="period" prefix="purchaseRight" options={options.period}
                                                    isDisabled={noPurchaseRight} state={formData} handleInputChange={handleInputChange} readOnly={readOnly} />
                                            </Flex>
                                        </>
                                    }
                                    <Box textAlign="right" mt="6">
                                        {!needsAdmin &&
                                            <Button mt={["6"]} variant="ghost" color="brand.darkblue" onClick={() => history.push(`/company/${businessId}`)} >
                                                {t('userform.Return-to-company')}
                                            </Button>
                                        }
                                        {(isNewUser && !needsAdmin) &&
                                            <Button type="reset" mt={["6"]} ml="4" variant="ghost" color="brand.darkblue" onClick={() => resetForm()} >
                                                {t('userform.Reset-form')}
                                            </Button>
                                        }
                                        <Button type="submit" aria-label={t('userform.Submit-user')} mt={["6"]} ml="4" onClick={() => submitForm()} disabled={formErrors || waitingAdmin} isLoading={loading} loadingText={t('userform.Submitting')}>
                                            {isNewUser
                                                ? t('userform.Submit-user')
                                                : t('userform.Submit-changes')}
                                        </Button>
                                    </Box>
                                    {companyErrors &&
                                        <MessageBox isOpen={show} type={"error"} errorObject={companyErrors} />
                                    }
                                    {userSaved &&
                                        <MessageBox isOpen={true} type={"success"} message={t('userform.Userinfo-saved')} redirect={needsAdmin ? "" : `/company/${businessId}`} />
                                    }
                                </Box>
                                }
                                {user.state === WAITING_APPROVAL &&
                                    <Approval formUser={user} businessId={businessId} hide={hide} />
                                }
                            </Box>
                        </Box>
                        :
                        <>
                            {!loading &&
                                < Box mt="10%">
                                    <Heading as="h2" textStyle="h3">{company ? t('userform.No-user-found') : t('companylist.No-company-found')}</Heading>
                                </Box>
                            }
                        </>
                    }
                </Container>
            }
        </>
    );
}


export default UserForm;