import React from 'react';
//providers
import { useSelector } from 'react-redux';
import { getAuthUser, getAuthErrors } from '../redux/selectors';
//theme
import { Box, CSSReset } from '@chakra-ui/react';
//compontents
import Login from './Login.js';
import { Redirect } from 'react-router';


const FrontPage = () => {

  const authUser = useSelector(getAuthUser());
  const error = useSelector(getAuthErrors);

  const bgClass = authUser ? "" : "with-bg";

  return (
    <Box className={bgClass}>
      <CSSReset />
      {(authUser)
        ? <Redirect to={{ pathname: "/companies" }} />
        : <Login error={error} />
      }
    </Box >
  );
}

export default FrontPage;
