import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
//providers
import { useTranslation } from 'react-i18next';
//theme
import { Box, Heading, Flex, Container, Input, IconButton, InputGroup, InputRightElement, Button } from '@chakra-ui/react';
import { ReactComponent as SearchIcon } from '../images/searchicon.svg';
//redux
import { getAuthExpiring, getCompaniesByEmail } from '../redux/selectors';
import { useDispatch, useSelector } from "react-redux";
import { fetchAuthUserCompaniesByEmail, requestCompanyActivation, tryCompanyActivation, refreshAuth } from '../redux/authUserSlice';
import { validateInput } from '../redux/helpers';
//compontents
import BreadcrumbNav from './BreadcrumbNav';

export const CompanyListBox = ({ company, email }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [triedVerification, setTriedVerification] = useState(false);
  const [companyName, setCompanyName] = useState(company.companyName);

  useEffect(() => {
    if (company.companyName !== companyName) {
      setTriedVerification(false);
      setCompanyName(company.companyName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);

  const identifier = company.businessId;
  const handleRequestActivation = (e) => {
    dispatch(requestCompanyActivation({ email: email, companyIdentifier: identifier}));
  };

  const handleTryActivation = (e) => {
    setTriedVerification(true);
    dispatch(tryCompanyActivation({ email: email, companyIdentifier: identifier,  pinCode: e}));
  };

  const isActive = company.state === "ACTIVE";
  const canDoChanges = company.state !== "ACTIVE" && company.state !== "LOCKED";
  const initialState = company.state === "INITIAL_STATE" || company.state === "EXPIRED_PIN";
  const waitingForPin = company.state === "WAITING_FOR_PIN";

  var newVal = null;
  return (
    <Box className="card" mb="4" textAlign="left" >
      <Flex p="4" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Box minWidth={["60%", "30%"]} >
          <Box textStyle="h2">
              <Box color="black">{company.companyName}</Box>
          </Box>
          <Box color="black">{company.businessId}</Box>
        </Box>
        <Box minWidth="30%" textAlign="right" alignSelf={["flex-end", "auto"]}> 
          <Flex p="4" justifyContent="space-between" alignItems="center" flexWrap="wrap">
            {canDoChanges && waitingForPin &&
            <Box minWidth="30%" textAlign="right"> 
                  <InputGroup>
                    <Input height="48px" borderRadius="6px" type="text" onKeyDown={(e) => {if (e.key === "Enter") handleTryActivation(newVal);}}
                     onChange={(e) => newVal = e.target.value} placeholder={t("companyactivation.verificationcode")} /> 
                  </InputGroup> 
            </Box>
            }
            { canDoChanges && (waitingForPin || initialState) &&
            <Box width={["42px", "42px", "20%"]} >
              <Button p="12px" variant="outline" onClick={() => waitingForPin ? handleTryActivation(newVal) : handleRequestActivation()}>{waitingForPin ? t("companyactivation.verify") : t("companyactivation.sendmail")}</Button>
            </Box>
            }
            {
              isActive && 
              <Box width={["42px", "42px", "20%"]} >
                <Button p="12px" variant="outline" onClick={() => history.push(`/company/${company.businessId}`)}>{t("companyactivation.proceed")}</Button>
              </Box>
            }
          </Flex>
        </Box>
      </Flex>
      {
        waitingForPin && canDoChanges &&
          <Box textStyle="infomsg">
              <Box color="black">{t("companyactivation.sent")}</Box>
          </Box>
      }
      {
        company.state === "ACTIVE" && 
          (triedVerification ?
            <Box textStyle="successmsg">
                <Box color="black">{t("companyactivation.verificationsuccess")}</Box>
            </Box>
            :
            <Box textStyle="successmsg">
                <Box color="black">{t("companyactivation.success")}</Box>
            </Box>
          )
      } 
      {
        company.state === "LOCKED" &&
          <Box textStyle="errormsg">
              <Box color="black">{t("companyactivation.locked")}</Box>
          </Box>
      }
      {
        company.state === "EXPIRED_PIN" &&
          <Box textStyle="errormsg">
              <Box color="black">{t("companyactivation.expired")}</Box>
          </Box>
      }
      {
        company.state === "NOT_APPLICABLE" &&
          <Box textStyle="errormsg">
              <Box color="black">{t("companyactivation.not_applicable")}</Box>
          </Box>
      }
      {
        company.information &&
          <Box textStyle="errormsg">
              <Box color="black">{company.information}</Box>
          </Box>
      }
    </Box>
  );
}

const ActivateCompany = () => {
  const { t, ready } = useTranslation();
  const dispatch = useDispatch();
  const authExpiring = useSelector(getAuthExpiring());
  const foundCompanies = useSelector(getCompaniesByEmail);

  const [emailAddress, setEmailAddress] = useState('');
  const [businessId, setBusinessId] = useState('');
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (authExpiring) {
      dispatch(refreshAuth());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authExpiring]);

  useEffect(() => {
    if (searching) {
      setSearching(false);
      if (!foundCompanies || foundCompanies.companies.length === 0) {
        setError(true);
      }
      else {
        setError(null);
      }
    }
    else {
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundCompanies]);

  const addDash = (string) => {
    return string.includes("-") ? string : string.substr(0, string.length-1)+"-"+string.substr(string.length-1);
  }

  const handleSearch = () => {
    if (emailAddress && validateInput('email', emailAddress) && businessId && validateInput('businessId', businessId)) {
      setSearching(true);
      dispatch(fetchAuthUserCompaniesByEmail({ email: emailAddress, businessId: addDash(businessId) }));
    }
  };

  const breadcrumbLinks = [
    { url: "/", text: "breadcrumb.Company-selection" },
    { url: `/activatecompany`, text: "breadcrumb.Activatecompany" }
  ]

  return (
    <>
      { ready &&
      <>
        <Container as="main" maxW="55em" pb="9%">
          <BreadcrumbNav links={breadcrumbLinks} />
          <Heading mt="10%" as="h1" textStyle="h1" fontWeight={["400"]} mb="3">
            {t("companyactivation.title")}
          </Heading>
          <Flex flexDirection={["column", "row"]}>
            <Input height="48px" borderRadius="6px" type="text" value={businessId} onKeyDown={(e) => {if (e.key === "Enter") handleSearch();}}
              onChange={e => setBusinessId(e.target.value)} placeholder={t("companyactivation.businessid")} /> 
            <InputGroup mb="14" maxWidth="35em" mx="auto" height="48px">
              <Input height="48px" borderRadius="6px" type="text" value={emailAddress} onKeyDown={(e) => {if (e.key === "Enter") handleSearch();}}
                onChange={e => setEmailAddress(e.target.value)} placeholder={t("form.email")} /> 
              <InputRightElement height="48px" width="4.5rem">
                <IconButton height="48px" width="100%" className="filled fillicon" border="none" aria-label="Search" icon={<SearchIcon />} onClick={handleSearch}/>
              </InputRightElement>
            </InputGroup>
          </Flex>
          {
            error && (
            <>
            <Heading mt="10%" as="h1" textStyle="h1" fontWeight={["400"]} mb="3">
              {t("companyactivation.nonefound")}
            </Heading>
            <Heading mt="10%" as="h1" textStyle="h1" fontWeight={["400"]} mb="3">
              {t("companyactivation.nonefound2")}
            </Heading>
            </>
            )
          }
          <Box>
              {
                foundCompanies &&
                Object.keys(foundCompanies.companies).map(key => (
                  <CompanyListBox key={key} email={emailAddress} company={foundCompanies.companies[key]} />
                ))
              }
            </Box>
        </Container>
      </>
      }
    </>
  );
}

export default ActivateCompany;