import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from "i18next-http-backend";

let lng = localStorage.getItem('loc_id');
i18n.on('languageChanged', (lng) => {document.documentElement.setAttribute('lang', lng);})
i18n
    .use(backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        ...(lng) && {lng: lng},
        fallbackLng: "en",
        supportedLngs: ['fi', 'en', 'sv'],
        debug: false,
        preload: ['fi', 'en', 'sv'],
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: true,
            wait: true,
            bindI18n: 'languageChanged',
        }
    });
export default i18n;