import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from "react-router-dom";
import queryString from 'query-string';
//providers
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthUser, getSuperAdmin, getAuthExpiring } from '../redux/selectors';
import { setAuthToken, refreshAuth } from '../redux/authUserSlice';
import { logout } from '../redux/reducers';
//theme
import { Container, Heading } from '@chakra-ui/react';
//components
import ActivateCompany from './ActivateCompany';
import FrontPage from './FrontPage';
import UserList from './UserList';
import UserForm from './UserForm';
import CompanyList from './CompanyList';
import Header from '../components/Header';

const NotFound = () => {
    const { t } = useTranslation();

    return (
        <Container as="main" maxW="55em" textAlign="center">
            <Heading mt="10%" as="h1" textStyle="h1" mb="3">
                {t('login.not-found')}
            </Heading>
        </Container>
    );
}

function PrivateRoute({ children, path, match, ...rest }) {
    const authUser = useSelector(getAuthUser());
    const superAdmin = useSelector(getSuperAdmin);
    const prefix = superAdmin ? "superadmin" : "";

    return (
        <Route
            {...rest}
            render={({ location, match }) => (
                authUser ?
                    superAdmin
                        ? (<Redirect
                            to={{
                                pathname: `/${prefix}${match.url}`,
                                state: { from: location },
                            }}
                        />
                        )
                        : (children)
                    : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location }
                            }}
                        />
                    )
            )
            }
        />
    );
}

function SuperAdminRoute({ children, ...rest }) {
    const authUser = useSelector(getAuthUser());
    const superAdmin = useSelector(getSuperAdmin);
    return (
        <Route
            {...rest}
            render={({ location }) =>
                (authUser && superAdmin) ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: location }
                        }}
                    />
                )
            }
        />
    );
}

const Routes = () => {
    const { search } = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();

    const authUser = useSelector(getAuthUser());
    const authExpiring = useSelector(getAuthExpiring());

    useEffect(() => {
        const isSearchUrl = search.includes('access_token') || search.includes('error');
        const stored_token = sessionStorage.getItem('access_token');
        const token_type = sessionStorage.getItem('token_type');

        if (authUser) {
            if (authExpiring) {
                dispatch(refreshAuth());
            }
            //be happy and do nothing
        } else if (!authUser && (isSearchUrl || stored_token)) {
            const qString = queryString.parse(search);
            dispatch(setAuthToken({ access_token: qString.access_token ?? stored_token, token_type: qString.token_type ?? token_type, token_error: qString.error }));
            if (history.location.state && history.location.state.from.pathname) {
                history.push(history.location.state.from.pathname);
            }
        } else {
            dispatch(logout());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, search]);

    return (
        <>
            <Header />
            <Switch>
                <Route exact path="/">
                    <FrontPage />
                </Route>
                <PrivateRoute exact path="/companies">
                    <CompanyList />
                </PrivateRoute>
                <PrivateRoute exact path="/company/:businessId">
                    <UserList />
                </PrivateRoute>
                <PrivateRoute exact path="/user/:businessId/:userId">
                    <UserForm />
                </PrivateRoute>
                <PrivateRoute exact path="/adduser/:businessId">
                    <UserForm />
                </PrivateRoute >
                <PrivateRoute exact path="/activatecompany">
                    <ActivateCompany/>
                </PrivateRoute>

                <Route exact path="/superadmin">
                    <FrontPage />
                </Route>
                <SuperAdminRoute exact path="/superadmin/companies/">
                    <CompanyList />
                </SuperAdminRoute >
                <SuperAdminRoute exact path="/superadmin/company/:businessId">
                    <UserList />
                </SuperAdminRoute >
                <SuperAdminRoute exact path="/superadmin/user/:businessId/:userId">
                    <UserForm />
                </SuperAdminRoute>
                <SuperAdminRoute exact path="/superadmin/adduser/:businessId">
                    <UserForm />
                </SuperAdminRoute>


                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </>
    )
};

export default Routes;
