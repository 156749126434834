import React from "react";
import PropTypes from "prop-types";
import { Link as RLink } from "react-router-dom";
//providers
import { useTranslation } from "react-i18next";
//theme
import { Box, UnorderedList, ListItem } from "@chakra-ui/react"
import { ReactComponent as BackArrowIcon } from "../images/backarrowicon.svg";
import { ReactComponent as ChevronrightIcon } from "../images/chevronrightblueicon.svg";


const BreadcrumbNav = ({ links }) => {
    const { t } = useTranslation();
    const back = Array.isArray(links) ? links.length - 1 : 0;

    return (
        <Box as="nav" aria-label={t("breadcrumb.Breadcrumbs")} mt="8" textStyle="breadcrumb" >
            {back > 0 &&
                <Box display={["block", "none"]}><RLink to={links[back - 1].url} aria-label={t("breadcrumb.Back")}><BackArrowIcon /></RLink></Box>
            }
            <UnorderedList role="menu" display={["none", "block"]} className="breadcrumbitems" ml="0">
                {
                    Object.keys(links).map(key => (
                        <ListItem role="none" key={key} display="inline-block" mr="2">
                            <Box display="inline-block" mr="2"><ChevronrightIcon /></Box>
                            <RLink role="menuitem" to={links[key].url}>{t(links[key].text)}</RLink>
                        </ListItem>
                    ))
                }
            </UnorderedList>
        </Box >
    );
}


BreadcrumbNav.propTypes = {
    links: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    })).isRequired
}

export default BreadcrumbNav;