import React, { useState } from 'react';
import { Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
//providers
import { useTranslation } from 'react-i18next';
//theme
import { Box, Heading, Flex, Button, Modal, useDisclosure } from '@chakra-ui/react';
import { ReactComponent as FreezeRedIcon } from '../images/freezeredicon.svg';
import { ReactComponent as DeleteRedIcon } from '../images/deleteredicon.svg';
import { ReactComponent as ActivateIcon } from '../images/activateicon.svg';
//redux
import { useDispatch, useSelector } from "react-redux";
import { getSuperAdmin, getCompanyErrors, findCompanyById } from '../redux/selectors';
import { ACTIVE, WAITING_APPROVAL, FROZEN, DRAFT, DISABLED, ADMIN, SUPERADMIN } from '../redux/helpers';
//compontents
import Popup from './Popup';
import MessageBox from './MessageBox';
import CompanyBox from './CompanyBox';
import { deleteActor, deleteSuperadminActor, defreezeActor, freezeActor, activateActor, disableActor } from '../redux/companiesSlice';


const UserFormHeader = ({ user, loading, showError, businessId, hide }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const superAdmin = useSelector(getSuperAdmin);
    const companyErrors = useSelector(getCompanyErrors);
    const company = useSelector(findCompanyById(businessId));

    const [modalid, setModalid] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure();

    const userState = user && user.state ? user.state : "";
    const needsAdmin = company && (company.state === DRAFT || company.state === WAITING_APPROVAL);
    const waitingAdmin = user && userState === WAITING_APPROVAL;
    const userId = user && user.id;
    const newUser = userId === "NEW" || !user;


    const deleteUser = () => {
        if (user && user.role === SUPERADMIN) {
            dispatch(deleteSuperadminActor({ userId: userId, businessId: businessId }));
        }
        else {
            dispatch(deleteActor({ userId: userId, businessId: businessId }));
        }
        onClose();
        showError(true);
    }

    const freezeUser = () => {

        if (superAdmin) {
            if (userState === ACTIVE || userState === DISABLED) {
                dispatch(freezeActor({ userId: userId, businessId: businessId }));
            }
        } else {
            if (userState === ACTIVE) {
                dispatch(disableActor({ userId: userId, businessId: businessId }));
            }
        }
        showError(true);
    }

    const activateUser = () => {
        if (superAdmin) {
            if (userState === FROZEN || userState === DISABLED) {
                dispatch(defreezeActor({ userId: userId, businessId: businessId }));
            }
        } else {
            if (userState === DISABLED) {
                dispatch(activateActor({ userId: userId, businessId: businessId }));
            }
        }
        showError(true);
    }

    const handleUserPopup = (modalid) => {
        setModalid(modalid);
        onOpen();
    }

    return (
        <>
            {(!companyErrors && !loading && company && company.redirect === "redirect") &&
                <Redirect to={`/company/${businessId}`} />
            }
            <Box mt="10%">
                <CompanyBox company={company} />
                <Flex justifyContent="space-between" alignItems="center" mb="6">
                    <Heading as="h1" textStyle="h1" textAlign="left">
                        {(needsAdmin || waitingAdmin)
                            ? t('userform.Service-activation')
                            : user && user.person && user.person.firstNames
                                ? <> {t("userform.Edit-user-info", { name: user.person.firstNames + " " + user.person.lastName })}</>
                                : <> {t('userlist.Add-user')}</>
                        }
                    </Heading>
                    <Box minWidth="142px" textAlign="right" ><Box display="inline-block" textStyle="badge" className={user ? userState : ""} borderRadius="2px" px="4px">
                        {t((user && user.role === ADMIN && (userState === DISABLED || userState === FROZEN)) ? "DISABLED-ADMIN" : userState)}
                    </Box></Box>
                </Flex>
                {(needsAdmin || waitingAdmin) && !hide &&
                    <MessageBox type="info" message={waitingAdmin ? t('userlist.Admin-waiting') : superAdmin ? t("userform.First-admin") : t("userform.Self-to-admin")} />
                }
                {!newUser &&
                    <Box textAlign="right">
                        <>
                            <Button mb={["4"]} variant="redoutline" onClick={() => { handleUserPopup(userId) }}>
                                <Box display="inline-block" ml="-3" mr="1"><DeleteRedIcon className="outline" /></Box>
                                {t('userlist.Delete-user')}
                            </Button>
                            {(userState === DISABLED || userState === FROZEN) &&
                                <Button isDisabled={userState === FROZEN && !superAdmin} variant="greenoutline" mb={["4"]} ml={["2", "4"]} onClick={() => { activateUser() }}>
                                    <Box display="inline-block" ml="-3" mr="1"><ActivateIcon className="outline" /></Box>
                                    {t('userform.Return-rights')}
                                </Button>
                            }
                            {(userState === ACTIVE || (userState === DISABLED && superAdmin)) &&
                                <Button variant="redoutline" mb={["4"]} ml={["2", "4"]} onClick={() => { freezeUser() }}>
                                    <Box display="inline-block" ml="-3" mr="1"><FreezeRedIcon className="outline" /></Box>
                                    {t('userform.Freeze-rights')}
                                </Button>
                            }
                        </>
                    </Box>
                }
            </Box>
            <Modal modalid={modalid} isOpen={isOpen} onClose={onClose} key={modalid}>
                <Popup userId={modalid} businessId={businessId} text={"DELETE"} acceptButton={{ text: 'userlist.Delete-user', fx: deleteUser }} cancelButton={{ text: 'userlist.Cancel', fx: onClose }} />
            </Modal>
        </>
    )
}

UserFormHeader.propTypes = {
    user: PropTypes.shape({
        state: PropTypes.string,
        role: PropTypes.string,
        userId: PropTypes.string,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        person: PropTypes.shape({
            firstNames: PropTypes.string,
            lastName: PropTypes.string,
        }),
        purchaseRight: PropTypes.shape({
            maxAmount: PropTypes.string,
            currencyCode: PropTypes.string,
            period: PropTypes.string,
        })
    }),
    loading: PropTypes.string,
    showError: PropTypes.func,
    businessId: PropTypes.string
}

export default UserFormHeader;