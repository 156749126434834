import React, { useEffect } from "react";
//providers
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
//theme
import { Box, Heading, Flex, Button } from "@chakra-ui/react";
import { ReactComponent as FreezeRedIcon } from "../images/freezeredicon.svg";
import { ReactComponent as ActivateIcon } from "../images/activateicon.svg";
import { ReactComponent as PdfIcon } from "../images/pdficon.svg";
//redux
import { FROZEN, DRAFT, WAITING_APPROVAL, ACTIVE } from "../redux/helpers";
import { useDispatch, useSelector } from "react-redux";
import { freezeCompany, activateCompany, activatePendingCompany } from "../redux/companiesSlice";
import { fetchApprovalContract } from "../redux/approvalsSlice";
import { getContractUrl, getSuperAdmin } from "../redux/selectors";


const CompanyBox = ({ company }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const superAdmin = useSelector(getSuperAdmin);

    const address = company ? company.address ?? null : null
    const cname = company ? company.name ?? company.companyName ?? "" : "";
    const businessId = company ? company.businessId : "";
    const contractUrl = useSelector(getContractUrl(businessId));

    const handleFreezeCompany = () => {
        if (superAdmin) {
            dispatch(freezeCompany({ companyId: company.id, businessId: company.businessId }));
        }
    }

    const handleActivateCompany = () => {
        if (superAdmin) {
            dispatch(activateCompany({ companyId: company.id, businessId: company.businessId }));
        }
    }

    const handleActivatePendingCompany = () => {
        if (superAdmin) {
            dispatch(activatePendingCompany({ companyId: company.id, businessId: company.businessId }));
        }
    }

    useEffect(() => {
        if (company && (company.state === ACTIVE || (superAdmin && company.state === WAITING_APPROVAL )) && !contractUrl) {
            dispatch(fetchApprovalContract({ companyId: company.id, businessId: company.businessId }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            { company &&
                <>
                    <Flex mt="10%" justifyContent="space-between" >
                        <Heading as="h2" textStyle="h2">
                            {cname}
                        </Heading>
                        <Box><Box className={company.status ?? company.state} display="inline-block" textStyle="badge" borderRadius="2px" px="4px">{t(company.status ?? company.state)}</Box></Box>
                    </Flex>
                    <Flex justifyContent="space-between">
                        <Box mb="7" color="black">
                            <Box mb="3">{company && company.businessId} </Box>
                            {
                                address &&
                                <>
                                    {address.streetAddress} <br />
                                    {address.postCode} {address.postOffice}
                                </>
                            }
                            {((company.state === ACTIVE && contractUrl) || (company.state === WAITING_APPROVAL && contractUrl && superAdmin)) &&
                                <Box mt="3">
                                    <a download={t("userlist.contract-filename") + ".pdf"} href={contractUrl} >
                                        <Flex>
                                            {t("userlist.Show-signitures")}
                                            <Box display="inline-block" ml="2" mr="1"><PdfIcon className="outline" /></Box>
                                        </Flex>
                                    </a>
                                </Box>
                            }
                        </Box>
                        <Box mt="3">
                            {superAdmin && (
                                <>
                                    {([DRAFT, ACTIVE, WAITING_APPROVAL].includes(company.state)) &&
                                        <Button variant="redoutline" mb={["4"]} ml={["2", "4"]} onClick={() => { handleFreezeCompany() }}>
                                            <Box display="inline-block" ml="-3" mr="1"><FreezeRedIcon className="outline" /></Box>
                                            {t("userform.Freeze-company")}
                                        </Button>
                                    }
                                    {(company.state === WAITING_APPROVAL) &&
                                        <Button variant="greenoutline" mb={["4"]} ml={["2", "4"]} onClick={() => { handleActivatePendingCompany() }}>
                                            <Box display="inline-block" ml="-3" mr="1"><ActivateIcon className="outline" /></Box>
                                            {t("userform.Activate-company")}
                                        </Button>
                                    }
                                    {(company.state === FROZEN) &&
                                        <Button variant="greenoutline" mb={["4"]} ml={["2", "4"]} onClick={() => { handleActivateCompany() }}>
                                            <Box display="inline-block" ml="-3" mr="1"><ActivateIcon className="outline" /></Box>
                                            {t("userform.Return-company")}
                                        </Button>
                                    }
                                </>
                            )}
                        </Box>
                    </Flex>
                </>
            }
        </>
    )
}

CompanyBox.propTypes = {
    company: PropTypes.shape({
        status: PropTypes.string,
        companyStatusCode: PropTypes.string,
        businessId: PropTypes.string.isRequired,
        companyName: PropTypes.string,
        address: PropTypes.shape({
            streetAddress: PropTypes.string,
            postCode: PropTypes.string,
            postOffice: PropTypes.string,
        }),
    })
}

export default CompanyBox;