import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
//import the root reducer
import rootReducer from './reducers';

// create an object for the default data
/*const defaultState = {
    authUser: { loading: "", error: "", user: null, },
    companies: { loading: "", error: "", companies: [] },
    approvals: { loading: "", error: "", companies: [], requests: [] }
}*/

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production',
});

export default store;