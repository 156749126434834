import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
//providers
import { useTranslation } from "react-i18next";
//theme
import { Box, Heading, Button, InputGroup, InputLeftAddon, Select } from "@chakra-ui/react";
import { ReactComponent as ExternalArrowIcon } from "../images/externalarrowicon.svg";
import { ReactComponent as PlusIcon } from "../images/plusicon.svg";
import { ReactComponent as SelectUserIcon } from "../images/selectusericon.svg";
//redux
import { getApprovalLoading, findCompanyById, getApprovalCompanyStatus, getApprovalNames, findNewUserBySSN } from "../redux/selectors";
import { useSelector, useDispatch } from "react-redux";
import { getSuperAdmin, getAuthUser } from "../redux/selectors";
import { fetchApprovalStatus, sendApprovalRequest } from "../redux/approvalsSlice";
//compontents
import FormElement from "./FormElement";
import { validateInput, WAITING_APPROVAL, DRAFT } from "../redux/helpers";


const Approval = ({ formUser, businessId, hide }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUser());
  const authUserId = authUser ? authUser.id : "";

  const history = useHistory();
  const loading = useSelector(getApprovalLoading);
  const superAdmin = useSelector(getSuperAdmin);
  const company = useSelector(findCompanyById(businessId));
  const companyStatus = useSelector(getApprovalCompanyStatus(businessId));
  const approvalNames = useSelector(getApprovalNames(businessId, authUserId));
  const singleSigner = companyStatus ? companyStatus.currentActorApprovesAlone : false;
  const signUrl = companyStatus && companyStatus.signingUrl ? companyStatus.signingUrl : "";
  let signers = [];

  const newUser = useSelector(findNewUserBySSN(formUser && formUser.person ? formUser.person.socialSecurityNumber : "", businessId));

  const user = (formUser.state === WAITING_APPROVAL) ? formUser : newUser;

  useEffect(() => {
    if (company && [WAITING_APPROVAL, DRAFT].includes(company.state)) {
      //admin have to be saved first
      if (user && user.state === WAITING_APPROVAL) {
        dispatch(fetchApprovalStatus({ businessId, companyId: company.id }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUrl]);

  useEffect(() => {
    if (company && [WAITING_APPROVAL, DRAFT].includes(company.state)) {
      if (!signUrl && !superAdmin) {
        if (singleSigner || (companyStatus && companyStatus.sign_order_created)) {
          // no other singers needed so just send approval request OR
          // approval requests already done so just get the link
          dispatch(sendApprovalRequest({ businessId, companyId: company.id, signers: [], language:  t("approvalrequests.Language")}));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyStatus]);

  const initState = {
    emailSent: false,
    errors: {
      email1: "",
      email1Select: "",
      email2: "",
      email2Select: "",
    },
    emails: {
      email1: { email: "", name: "", bisnodeId: "" },
      email2: { email: "", name: "", bisnodeId: "" },
    },
  };

  const [poll, setPoll] = useState(false);
  const [emailList, setEmails] = useState(initState);
  const emailErrors = (Object.values(emailList.errors).find(value => value !== "")) ? true : false;
  const emailEmpty = Object.values(emailList.emails).find(item => item.email !== "") ? false : true;

  useEffect(() => {
    const interval = setInterval(() => {
      if (poll && poll === true) {
        if (companyStatus && companyStatus.state !== WAITING_APPROVAL) {
          history.push(`/company/${businessId}`);
        }
        dispatch(fetchApprovalStatus({ businessId, companyId: company.id }));
      }
    }, 5000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poll, companyStatus]);

  const handleEmails = (e) => {
    let property = e.target.name;
    let value = e.target.value.trim();
    let update = {
      ...emailList["emails"], [property]: { ...emailList["emails"][property], email: value }
    };
    let error = "";
    let errorSelect = "";

    if (value) {
      error = validateInput("email", value) ? "" : t("userform.invalid-input", { inputname: t("form.email").toLowerCase() });
      if (emailList["emails"][property].bisnodeId === "") {
        errorSelect = t("form.Signer-missing");
      }
    }

    setEmails({
      ...emailList,
      emails: update,
      errors: { ...emailList["errors"], [property]: error, [property + "Select"]: errorSelect }
    });
  }


  const handleSelectChange = (e, key) => {
    const { options, selectedIndex, value } = e.target;
    let error = emailList["errors"][key + "Select"];

    if (emailList["emails"][key].email !== "") {
      if (value !== "") {
        error = "";
      } else {
        error = t("form.Signer-missing");
      }
    }

    setEmails({
      ...emailList,
      emails: {
        ...emailList.emails, [key]:
          { ...emailList.emails[key], name: options[selectedIndex].text, bisnodeId: value }
      },
      errors: { ...emailList["errors"], [key + "Select"]: error }
    });
  }


  const addEmailInput = () => {
    let index = Object.keys(emailList.emails).length;
    const key = index > 0 ? "email" + (index + 1) : "email1";
    const emailsPlus = {
      emails: {
        ...emailList.emails, [key]: { email: "", name: "", bisnodeId: "" },
      },
      errors: {
        ...emailList.errors, [key]: "", [key + "Select"]: ""
      },
    }
    setEmails(emailsPlus);
  }

  const sendEmails = () => {
    const errors = (Object.values(emailList.errors).find(value => value !== "")) ? true : false;
    if (errors === false) {

      Object.keys(emailList.emails).map(key => {
        if (emailList.emails[key].email !== "" && emailList.emails[key].bisnodeId !== "") {
          signers.push({ bisnodeId: emailList.emails[key].bisnodeId, email: emailList.emails[key].email });
        }
        return true;
      });

      dispatch(sendApprovalRequest({ businessId, companyId: company.id, signers, language:  t("approvalrequests.Language") }));
      setEmails({
        ...emailList,
        emails: initState["emails"],
        emailSent: true
      });
    }

  }

  return (
    <Box>
      {(!singleSigner && companyStatus && !companyStatus.sign_order_created && poll === false && approvalNames.length > 0) && (
        <>
          <Heading as="h2" textStyle="h3" mt="12" mb="3"> {t("terms.Request-approval")}</Heading>
          <Box mt="5">{t("terms.Request-approval-info")}</Box>
          <Box mt="6">
            {
              Object.keys(emailList.emails).map((key, index) => (
                <InputGroup className="approval-group" key={key}>
                  <InputLeftAddon>
                    <Select className="approval-select" key={key} placeholder={t("form.Choose-signer")} onChange={(e) => handleSelectChange(e, key)} >
                      {
                        approvalNames.map((item, key) => (
                          <option key={key} value={item.bisnodeId}>{t(item.name)}</option>
                        ))
                      }
                    </Select>
                    <SelectUserIcon />
                  </InputLeftAddon>
                  <Box width="40%" pt="10px">
                    <Box>{emailList.emails[key].bisnodeId ? emailList.emails[key].name : t("form.Choose-signer")}</Box>
                    <Box pt="10px" textStyle="errortext" pl="0"> {emailList.errors[key + "Select"]} </Box>
                  </Box>
                  <FormElement key={index} name={key} prefix={"emails"} type="approvalInput" state={emailList}
                    handleInputChange={handleEmails} placeholder={t("form.Email-of-signer", { signer: emailList.emails[key].name })} />
                </InputGroup>
              ))
            }
            <Box><Button variant="ghost" color="brand.darkblue" onClick={() => addEmailInput()}>
              <Box display="inline-block" ml="-1" mr="1"> <PlusIcon /> </Box>{t("terms.Add-email-row")}</Button>
            </Box>
          </Box>
          <Box textAlign="right" mt="4">
            <Button ml="4" onClick={() => sendEmails()} disabled={emailErrors || (user.state !== WAITING_APPROVAL) || emailEmpty} isLoading={loading} loadingText={t("userform.Submitting")}>
              {t("terms.Send-request")}
            </Button>
          </Box>
        </>
      )}

      {hide && !signUrl && 
        (<Box textAlign="center" mt="8"><Button isLoading={true} loadingText={t("terms.Wait-for-terms")} variant="outline" spinnerPlacement='end'></Button></Box>) //current version doesn't havve spinnerPlacement implemented
      }

      {(!superAdmin && signUrl) &&
        <>
          <Heading as="h2" textStyle="h3" mt="12" mb="3"> {t("terms.Accept-terms")}</Heading>
          <Box mt="5"> {t("terms.Accept-terms-info")}</Box>
          <Box textAlign="right" mt="4">
            <a href={signUrl} rel="noopener noreferrer" target="_blank">
              <Button disabled={signUrl ? false : true} className="fillicon" onClick={() => setPoll(true)}>
                {t("terms.Sign-terms")} < Box display="inline-block" ml="2" mr="-1"><ExternalArrowIcon /></Box>
              </Button>
            </a>
          </Box>
        </>
      }
    </Box >
  );
}

Approval.propTypes = {
  formUser: PropTypes.shape({
    state: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    userId: PropTypes.string,
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
    person: PropTypes.shape({
      firstNames: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    }),
    purchaseRight: PropTypes.shape({
      maxAmount: PropTypes.string,
      currencyCode: PropTypes.string,
      period: PropTypes.string,
    })
  }),
  businessId: PropTypes.string.isRequired
}


export default Approval;
