import React from "react";
import PropTypes from "prop-types";
//providers
import { useTranslation } from "react-i18next";
//theme
import { Box, Heading } from "@chakra-ui/react";
//redux
import { getSuperAdmin } from "../redux/selectors";
import { useSelector } from "react-redux";

//compontents
import { CompanyListBox } from './CompanyList';

const ApprovalRequests = ({ requests }) => {
    const { t } = useTranslation();
    const superAdmin = useSelector(getSuperAdmin);

    return (
        <Box>
            { (requests && requests.length > 0) &&
                <>
                    <Heading as="h2" textStyle="h2" mb="5" mt="10">{t("companylist.Requests-waiting")} </Heading>
                    <Box>
                        {
                            Object.keys(requests).map(key => (
                                <CompanyListBox key={key} company={requests[key]} superAdmin={superAdmin} />
                            ))
                        }
                    </Box>
                </>
            }
        </Box>
    );
}

ApprovalRequests.propTypes = {
    requests: PropTypes.arrayOf(PropTypes.shape({
        status: PropTypes.string,
        companyStatusCode: PropTypes.string,
        businessId: PropTypes.string.isRequired,
        companyName: PropTypes.string,
        address: PropTypes.shape({
            streetAddress: PropTypes.string,
            postCode: PropTypes.string,
            postOffice: PropTypes.string,
        }),
    }))
}


export default ApprovalRequests;