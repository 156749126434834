import React from 'react';
import PropTypes from 'prop-types';
import { Link as RLink, useHistory } from "react-router-dom";
//providers
import { useTranslation } from 'react-i18next';
//theme
import { Box, Flex, Button } from '@chakra-ui/react';
import { ReactComponent as DeleteIcon } from '../images/deleteicon.svg';
import { ReactComponent as EditIcon } from '../images/editicon.svg';
import { ACTIVE, BUYER, ADMIN, DISABLED, FROZEN } from '../redux/helpers';


const UserBox = ({ user, handleUserPopup, businessId }) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Box className="card" mb="4" textAlign="left" textStyle="smalltext">
            <Flex p="4" justifyContent="space-between" flexWrap={["wrap", "no-wrap"]} alignItems={["flex-start", "center"]}>
                <Box display={["block", "flex"]} justifyContent="space-between" flexDirection={["column", "column", "row"]} minWidth={["auto", "85%"]} maxWidth={["200px", "auto"]} >
                    <Box width={["100%", "100%", "55%"]} mb={["2", "2", "0"]}>
                        <Box color="black" fontSize="16px" mr="1">
                            <RLink to={`/user/${businessId}/${user.id}`} >{user.person && user.person.firstNames} {user.person && user.person.lastName}  </RLink>
                        </Box>
                        <Box color="black" mr="1">{t(user.role)}</Box>
                    </Box>
                    <Box width={["100%", "100%", "45%"]} >
                        <Box color="black" mr="1">{user.email} </Box>
                        <Box color="black" mr="1">{user.phoneNumber} </Box>
                    </Box>
                    <Box className="badgewrap" minWidth="145px" textAlign="left">
                        {user.state === ACTIVE && user.role === BUYER ?
                            <>
                                <Box color="black" display={["inline-block", "inline-block", "block"]} mr="1" mt={["3", "3", "0"]}> {t('userlist.Purchase-limit')} </Box>
                                <Box color="black" display={["inline-block", "inline-block", "block"]} mr="1">
                                    {user.purchaseRight.maxAmount
                                        ? <>{user.purchaseRight.maxAmount} {user.purchaseRight.currencyCode}  / {t(user.purchaseRight.period)} </>
                                        : <>{t("userlist.not-limited")}</>
                                    }
                                </Box>
                            </>
                            : user.state !== ACTIVE &&
                            <Flex alignItems="center" h="100%"><Box mt={["3", "3", "0"]} display="inline-block" textStyle="badge" className={user.state} borderRadius="2px" px="4px" >
                                {user.role === ADMIN && (user.state === DISABLED || user.state === FROZEN) ? t("DISABLED-ADMIN") : t(user.state)}
                            </Box></Flex>
                        }
                    </Box>
                </Box>
                <Box width={["42px", "42px", "15%"]} textAlign="right" >
                    <Button aria-label={t("userlist.Delete-user")} onClick={() => handleUserPopup(user.id)} key={user.id} textStyle="badge" p="12px" className="filled" variant="red" m="1" mr="3"><DeleteIcon /></Button>
                    <Button aria-label={t("userlist.Edit-user")} onClick={() => history.push(`/user/${businessId}/${user.id}`)} textStyle="badge" p="12px" className="filled fillicon" variant="filled" m="1"><EditIcon /></Button>
                </Box>
            </Flex>
        </Box>
    );
}

UserBox.propTypes = {
    user: PropTypes.shape({
        state: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        userId: PropTypes.string,
        email: PropTypes.string.isRequired,
        phoneNumber: PropTypes.string.isRequired,
        person: PropTypes.shape({
            firstNames: PropTypes.string.isRequired,
            lastName: PropTypes.string.isRequired,
        }),
        purchaseRight: PropTypes.shape({
            maxAmount: PropTypes.string,
            currencyCode: PropTypes.string,
            period: PropTypes.string,
        })
    }),
    handleUserPopup: PropTypes.func.isRequired
}

export default UserBox;