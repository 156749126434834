import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { APIBASE, API_PERSON, fetchPostAPI } from './API';
import jwt_decode from "jwt-decode";


export const fetchAuthLink = createAsyncThunk("authUser/fetchAuthLink",
    async (_, { rejectWithValue }) => {
        const url = `${APIBASE}/user/authorization`;
        const result = fetchPostAPI(url, {}, rejectWithValue, "get", "text");
        return result;
    }
);

export const refreshAuth = createAsyncThunk("authUser/refreshAuth",
    async (_, { rejectWithValue }) => {
        const url = `${API_PERSON}/token/refresh`;
        const result = fetchPostAPI(url, {}, rejectWithValue, "get");
        return result;
    }
);

export const fetchAuthUserCompanies = createAsyncThunk("authUser/fetchAuthUserCompanies",
    async (_, { rejectWithValue }) => {
        const url = `${API_PERSON}/roles`;
        const result = fetchPostAPI(url, {}, rejectWithValue, "get");
        return result;
    }
);

export const fetchAuthUserCompaniesByEmail = createAsyncThunk("authUser/fetchAuthUserCompaniesByEmail",
    async ({email, businessId}, { rejectWithValue }) => {
        const url = `${API_PERSON}/roles/${email}/${businessId}`;
        const result = fetchPostAPI(url, {}, rejectWithValue, "get");
        return result;
    }
);

export const requestCompanyActivation = createAsyncThunk("authUser/requestCompanyActivation",
    async ({email, companyIdentifier}, { rejectWithValue }) => {
        const url = `${API_PERSON}/activation/${email}/${companyIdentifier}`;
        const result = fetchPostAPI(url, {}, rejectWithValue, "get");
        return result;
    }
);

export const tryCompanyActivation = createAsyncThunk("authUser/tryCompanyActivation",
    async ({email, companyIdentifier, pinCode}, { rejectWithValue }) => {
        const url = `${API_PERSON}/tryactivation/${email}/${companyIdentifier}/${pinCode}`;
        const result = fetchPostAPI(url, {}, rejectWithValue, "get");
        return result;
    }
);

function isRejectedAction(action) {
    let suffix = action.type.split("/")[0];
    return suffix === "authUser" && action.type.endsWith('rejected');
}

function isPendingAction(action) {
    let suffix = action.type.split("/")[0];
    return suffix === "authUser" && action.type.endsWith('pending');
}

const authUserSlice = createSlice({
    name: "authUser",
    initialState: { loading: "", link: "", user: { errors: [] } },
    reducers: {
        setAuthToken: (state, action) => {
            const { access_token, token_type, token_error } = action.payload;
            const user = access_token ? jwt_decode(access_token) : {};
            const expired = user.exp < (Date.now() / 1000);

            if (token_error || expired || action.payload.error) {
                //reset user state
                state.users = { errors: [] };
                if (action.payload.error) {
                    state.user.errors.push(action.payload);
                } else {
                    state.user.errors.push({ code: expired ? "token_expired" : token_error });
                    sessionStorage.removeItem('access_token');
                    sessionStorage.removeItem('token_type');
                }
            } else if (access_token) {
                state.user = { ...state.user, ...user, token: access_token, token_type: token_type };
                sessionStorage.setItem('access_token', access_token);
                sessionStorage.setItem('token_type', token_type);
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchAuthUserCompanies.fulfilled, (state, action) => {
                    if (action.payload.error) {
                        state.user.errors.push(action.payload);
                    } else {
                        state.user = { ...state.user, ...action.payload };
                    }
                    state.loading = "";
                }
            )
            .addCase(
                fetchAuthUserCompaniesByEmail.fulfilled, (state, action) => {
                    if (action.payload.error) {
                        state.user.errors.push(action.payload);
                    } else {
                        state.activationCompanies = action.payload;
                    }
                    state.loading = "";
                }
            )
            .addCase(
                requestCompanyActivation.fulfilled, (state, action) => {
                    if (action.payload.error) {
                        state.user.errors.push(action.payload);
                    } else {
                        state.activationCompanies = action.payload;
                    }
                    state.loading = "";
                }
            )
            .addCase(
                tryCompanyActivation.fulfilled, (state, action) => {
                    if (action.payload.error) {
                        state.user.errors.push(action.payload);
                    } else {
                        state.activationCompanies = action.payload;
                    }
                    state.loading = "";
                }
            )
            .addCase(
                fetchAuthLink.fulfilled, (state, action) => {
                    if (action.payload.error || action.error) {
                        state.user.errors.push(action.error ? action.error : action.payload);
                    } else {
                        state.link = action.payload;
                    }
                    state.loading = "";
                }
            )
            .addCase(
                refreshAuth.fulfilled, (state, action) => {
                    if (action.payload.error) {
                        state.user.errors.push(action.payload);
                    } else {
                        const token = action.payload.access_token;
                        const user = token ? jwt_decode(token) : {};
                        state.user = { ...state.user, ...user, token: token };
                        sessionStorage.setItem('access_token', token);
                        sessionStorage.setItem('token_type', state.user.token_type);
                    }
                    state.loading = "";
                }
            )
            .addMatcher(
                isPendingAction, (state) => {
                    state.loading = "loading";
                }
            )
            .addMatcher(
                isRejectedAction, (state, action) => {
                    state.loading = "";
                    if (state.user) {
                        state.user.errors.push(action.payload ?? action.error);
                    }
                }
            )

    }
});

const reducer = authUserSlice.reducer;
export const { setAuthToken } = authUserSlice.actions;
export default reducer;

