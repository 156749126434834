import { createSelector } from '@reduxjs/toolkit';
import { SUPERADMIN } from './helpers';

export const getCompanies = state => state.companies.companies ?? [];
export const getCompaniesMore = state => state.companies.more ?? false;
export const getCompaniesSearchKey = state => state.companies.searchKey ?? "";
export const getCompanyLoading = state => state.companies.loading ?? "";
export const getCompanyErrors = state => state.companies.error ? state.companies.error : null;

export const getAuth = state => state.authUser ?? {};
export const getAuthErrors = state => state.authUser && state.authUser.user ? state.authUser.user.errors : [];
export const getAuthUserCompanies = state => state.authUser.user && state.authUser.user.companyRoles ? state.authUser.user.companyRoles : [];
export const getLoginLink = state => state.authUser.link ?? "";
export const getSuperAdmin = state => state.authUser.user && state.authUser.user.role === SUPERADMIN ? true : false;
export const getAuthLoading = state => (state && state.authUser.loading) ?? "";

export const getApprovalCompanies = state => state.approvals.companies ?? [];
export const getApprovals = state => state.approvals;
export const getApprovalLoading = state => state.approvals.loading;
export const getApprovalRequests = state => state.approvals.requests ?? [];

export const getCompaniesByEmail = state => {
    return state.authUser.activationCompanies ? state.authUser.activationCompanies : null;
}


export const getAuthExpiring = () => createSelector(
    [getAuth, getAuthErrors],
    (authUser) => {
        //5 min before expiry
        if (authUser.user && authUser.user.exp && ((authUser.user.exp - 300) < Date.now() / 1000)) {
            return true;
        } else {
            return false;
        }
    }
);

export const getAuthUser = () => createSelector(
    [getAuth, getAuthErrors],
    (authUser, authErrors) => {
        if (authUser.user && authUser.user.id && authUser.user.token) {
            if (authUser.user.exp && authUser.user.exp < (Date.now() / 1000)) {
                return false;
            }
            return authUser.user;
        }
        else {
            return false;
        }
    }
);

//Select company from authUser list of companies
export const selectCompanyById = (businessId) => createSelector(
    [getAuthUserCompanies],
    (companies) => {
        return companies.find(company => company.businessId === businessId)
    }
);

export const findCompanyById = (businessId) => createSelector(
    [getCompanies],
    (companies) => {
        return companies.find(company => company.businessId === businessId);
    }
);

export const getUsersOfCompany = (businessId) => createSelector(
    [getCompanies],
    (companies) => {
        const company = companies.find(company => company.businessId === businessId);
        return company ? company.users : [];
    }
)

export const findUserById = (userId, businessId) => createSelector(
    [getCompanies],
    (companies) => {
        let userFound = {};
        const company = companies.find(company => company.businessId === businessId);
        if (company) {
            return userFound = company.users ? company.users.find(user => user.id === userId) : false;
        }
        return userFound;
    }
);

export const findNewUserBySSN = (SSN, businessId) => createSelector(
    [getCompanies],
    (companies) => {
        let userFound = {};
        const company = companies.find(company => company.businessId === businessId);
        if (company) {
            return userFound = company.users ? company.users.find(user => user.person.socialSecurityNumber === SSN) : false;
        }
        return userFound;
    }
);

export const findUserBySaved = (businessId) => createSelector(
    [getCompanies],
    (companies) => {
        let userFound = {};
        const company = companies.find(company => company.businessId === businessId);
        if (company) {
            return userFound = company.users ? company.users.find(user => user.saved) : false;
        }
        return userFound;
    }
);


export const getApprovalCompanyStatus = (businessId) => createSelector(
    [getApprovalCompanies],
    (companies) => {
        const company = companies.find(company => company.businessId === businessId) ?? null;
        return company ?? null;
    }
);

export const getApprovalNames = (businessId) => createSelector(
    [getApprovalCompanies],
    (companies) => {
        const company = companies.find(company => company.businessId === businessId) ?? null;
        let approvalNames = [];
        if (company && (company.responsiblePersons || company.procurationPersons)) {
            if (company.responsiblePersons && company.responsiblePersons.length) {
                company.responsiblePersons.map((person) => {
                    if (person.bisnodeId) {
                        approvalNames.push({ bisnodeId: person.bisnodeId, name: person.firstNames + ' ' + person.lastName })
                    }
                    return true;
                });
            }
            if (company.procurationPersons && company.procurationPersons.length) {
                company.procurationPersons.map((person) => {
                    let duplicate = approvalNames.find(item => item.bisnodeId === person.bisnodeId) ? true : false;
                    if (person.bisnodeId && !duplicate) {
                        approvalNames.push({ bisnodeId: person.bisnodeId, name: person.firstNames + ' ' + person.lastName })
                    }
                    return true;
                });
            }
        }
        return approvalNames ?? [];
    }
);

export const getContractUrl = (businessId) => createSelector(
    [getApprovalCompanies],
    (companies) => {
        const company = companies.find(company => company.businessId === businessId);
        return (company && company.contractUrl) ? company.contractUrl : "";

    }
);


