import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_APPROVALS, fetchPostAPI, API_ADMIN } from './API';

/*const initialState = {
    error: [],
    loading: "",
    requests: [{
        name: "",
        businessId: "",
        users: [{
            id: "",
            role: "ADMIN",
            phoneNumber: "",
            email: "",
            state: "WAITING_APPROVAL",
            person: {
                "identifierCountryCode": "",
                "firstNames": "",
                "lastName": "",
                "socialSecurityNumber": ""
            }
        }]
    }],
    companies: [
        {
            "id": "daf8b242-5221-4ada-8d84-0aa30c74db99",
            "businessId": "2014838-7",
            "name": "Bisnode Finland Oy",
            "state": "ACTIVE",
            "registerState": "100",
            "currentActorApprovesAlone": false,
            "users": [
                {
                    "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                    "role": "BUYER",
                    "phoneNumber": "string",
                    "email": "string",
                    "state": "DRAFT",
                    "person": {
                        "identifierCountryCode": "FI",
                        "firstNames": "string",
                        "lastName": "string",
                        "socialSecurityNumber": "string"
                    },
                    "company": {
                        "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
                        "businessId": "string",
                        "name": "string",
                        "state": "DRAFT",
                        "registerState": "100"
                    },
                    "purchaseRight": {
                        "maxAmount": 0,
                        "currencyCode": "EUR",
                        "period": "WEEKLY"
                    }
                }
            ],
        }
    ]
};*/


export const fetchApprovalContract = createAsyncThunk("approvals/fetchApprovalContract",
    async ({ businessId, companyId }, { rejectWithValue }) => {
        const url = `${API_APPROVALS}/company/${companyId}/contract`;
        const result = fetchPostAPI(url, {}, rejectWithValue, "get", "blob");

        return result;
    }
);

export const fetchApprovalRequests = createAsyncThunk("approvals/fetchApprovalRequests",
    async (_, { rejectWithValue }) => {
        const url = `${API_ADMIN}/companies/pending_approval`;
        const result = fetchPostAPI(url, {}, rejectWithValue, "get");
        return result;
    }
);

export const fetchApprovalStatus = createAsyncThunk("approvals/fetchApprovalStatus",
    async ({ businessId, companyId }, { rejectWithValue }) => {
        const url = `${API_APPROVALS}/company/${companyId}/status`;
        const result = fetchPostAPI(url, {}, rejectWithValue, "get");
        return result;
    }
);

export const sendApprovalRequest = createAsyncThunk("approvals/sendApprovalRequest",
    async ({ businessId, companyId, signers, language }, { rejectWithValue }) => {
        const url = `${API_APPROVALS}/request`;
        const payload = {
            id: companyId,
            signers: signers,
            language: language
        }
        const result = fetchPostAPI(url, payload, rejectWithValue, "post");
        return result;
    }
);

const findCompanyIndex = (state, arg) => {
    let businessId = arg.businessId;
    let companyIndex = state.companies.findIndex(item => item.businessId === businessId);
    return companyIndex !== -1 ? companyIndex : null;
}

function isRejectedAction(action) {
    let suffix = action.type.split("/")[0];
    return suffix === "approvals" && action.type.endsWith('rejected');
}

function isPendingAction(action) {
    let suffix = action.type.split("/")[0];
    return suffix === "approvals" && action.type.endsWith('pending');
}

const approvalsSlice = createSlice({
    name: "approvalRequest",
    initialState: { loading: "", error: "", requests: [], companies: [] },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                fetchApprovalRequests.fulfilled, (state, action) => {
                    if (action.payload.error) {
                        state.error = action.payload;
                    } else {
                        state.requests = action.payload;
                    }
                    state.loading = "";
                }
            )
            .addCase(
                fetchApprovalContract.fulfilled, (state, action) => {
                    if (action.payload.error) {
                        state.error = action.payload;
                    } else {
                        const index = findCompanyIndex(state, action.meta.arg) ?? state.companies.length;
                        state.companies[index] = { ...state.companies[index], businessId: action.meta.arg.businessId, id: action.meta.arg.companyId, contractUrl: action.payload };
                        state.error = "";
                    }
                    state.loading = "";
                }
            )
            .addCase(
                fetchApprovalStatus.fulfilled, (state, action) => {
                    if (action.payload.error) {
                        state.error = action.payload;
                    } else {
                        const index = findCompanyIndex(state, action.meta.arg) ?? state.companies.length;
                        state.companies[index] = { ...state.companies[index], ...action.payload };
                        state.error = "";
                    }
                    state.loading = "";
                }
            )
            .addCase(
                sendApprovalRequest.fulfilled, (state, action) => {
                    if (action.payload.error) {
                        state.error = action.payload;
                    } else {
                        const index = findCompanyIndex(state, action.meta.arg) ?? state.companies.length;
                        state.companies[index] = { ...state.companies[index], ...action.payload };
                        state.error = "";
                    }
                    state.loading = "";
                }
            )
            .addMatcher(
                isPendingAction, (state) => {
                    state.loading = "loading";
                }
            )
            .addMatcher(
                isRejectedAction, (state, action) => {
                    state.loading = "";
                    state.error = action.payload ?? action.error;
                }
            )
    }
})

const reducer = approvalsSlice.reducer;
export default reducer;
