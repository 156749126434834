import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RLink } from "react-router-dom";
//providers
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
//theme
import { Box, Heading, Flex, Container, Input, IconButton, InputGroup, InputRightElement, Button } from '@chakra-ui/react';
import { ReactComponent as SearchIcon } from '../images/searchicon.svg';
//redux
import {
  getAuthUserCompanies, getCompanies, getCompanyLoading, getSuperAdmin, getCompanyErrors,
  getCompaniesMore, getCompaniesSearchKey, getAuthExpiring, getApprovalRequests
} from '../redux/selectors';
import { FROZEN } from '../redux/helpers';
import { useDispatch } from "react-redux";
import { searchCompanies, exportCsv } from '../redux/companiesSlice';
import { fetchAuthUserCompanies, refreshAuth } from '../redux/authUserSlice';
import { fetchApprovalRequests } from '../redux/approvalsSlice';
//compontents
import BreadcrumbNav from './BreadcrumbNav';
import MessageBox from './MessageBox';
import ApprovalRequests from './ApprovalRequests';


export const CompanyListBox = ({ company, superAdmin }) => {
  const { t } = useTranslation();

  const isActive = company.companyStatusCode === "100" || company.registerState === "100"; // Bisnode information
  const paymentStatus = !isActive ? FROZEN : company.purchaseRightStatusCode ?? company.state; //if no company status, company is not active our system yet

  const address = company && company.address ? company.address : null;
  return (
    <Box className="card" mb="4" textAlign="left" >
      <Flex className={isActive || "disabled"} p="4" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Box minWidth={["100%", "40%"]} >
          <Box textStyle="h2">
            {(isActive || superAdmin)
              ? <RLink to={`/company/${company.businessId}`} >{company.companyName ?? company.name} </RLink>
              : <Box color="black">{company.companyName ?? company.name}</Box>
            }
          </Box>
          <Box color="black">{company.businessId}</Box>
        </Box>
        <Box minWidth="40%">
          {address &&
            <>
              <Box color="black">{address.streetAddress} </Box>
              <Box color="black">{address.postCode} {address.postOffice}  </Box>
            </>
          }
        </Box>
        <Box className="badgewrap" minWidth="20%" textAlign="right" ml="auto" alignSelf={["flex-end", "auto"]}>
          <Box className={paymentStatus} display="inline-block" textStyle="badge" borderRadius="2px" px="4px" >{paymentStatus && t(paymentStatus)}</Box>
        </Box>
      </Flex>
    </Box>
  );
}

CompanyListBox.propTypes = {
  company: PropTypes.shape({
    status: PropTypes.string,
    companyStatusCode: PropTypes.string,
    businessId: PropTypes.string.isRequired,
    companyName: PropTypes.string,
    address: PropTypes.shape({
      streetAddress: PropTypes.string,
      postCode: PropTypes.string,
      postOffice: PropTypes.string,
    }),
  }),
  superAdmin: PropTypes.bool,
}

const SearchInput = ({ keyword, loading, handleSearch, t, searchKey }) => {
  const searchValue = keyword.keyword === null ? searchKey : keyword.keyword;
  return (
    <InputGroup mb="14" maxWidth="35em" mx="auto" height="48px">
      <Input height="48px" borderRadius="6px" type="search" value={searchValue} onChange={(e) => handleSearch(e)} placeholder={t("companylist.Search-company")} />
      {(!searchValue) &&
        <InputRightElement height="48px" width="4.5rem">
          <IconButton height="48px" width="100%" className="filled fillicon" border="none" isLoading={loading} aria-label="Search" icon={<SearchIcon />} />
        </InputRightElement>
      }
    </InputGroup>
  );
}

const CompanyList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const breadcrumbLinks = [
    { url: "/", text: "breadcrumb.Company-selection" }
  ];

  const superAdmin = useSelector(getSuperAdmin);
  const loading = useSelector(getCompanyLoading);
  const userCompanies = useSelector(getAuthUserCompanies);
  const foundCompanies = useSelector(getCompanies);
  const companyErrors = useSelector(getCompanyErrors);
  const moreCompanies = useSelector(getCompaniesMore);
  const authExpiring = useSelector(getAuthExpiring());
  const requests = useSelector(getApprovalRequests);
  let searchKey = useSelector(getCompaniesSearchKey);

  const [keyword, setKeyword] = useState({ keyword: null, loading: "", request: null });

  const handleSearch = (e) => {
    const value = e.target.value;
    setKeyword({ keyword: value, loading: "loading", request: "search" });
  };

  const handleExport = () => {
    setKeyword({ ...keyword, request: "export"});
    dispatch(exportCsv());
  };

  useEffect(() => {
    if (authExpiring) {
      dispatch(refreshAuth());
    }
    if (superAdmin) {
      if (keyword.keyword && keyword.request && keyword.request === "search" ) {
        const timer = setTimeout(() => {
          dispatch(searchCompanies({ keyword: keyword.keyword }));
          setKeyword({ ...keyword, loading: "" });
        }, 1000);
        return () => clearTimeout(timer);
      }
    } else {
      //if (!userCompanies.length) {
        dispatch(fetchAuthUserCompanies());
      //}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword.keyword, authExpiring]);

  useEffect(() => {
    if (superAdmin) {
      //get requests
      dispatch(fetchApprovalRequests());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const companies = superAdmin ? foundCompanies : userCompanies;

  return (
    <Container as="main" maxW="55em" textAlign="center" pb="9%">
      {loading &&
        <Box textAlign="center" mt="8"><Button isLoading={true} variant="outline"></Button></Box>
      }
      <Box>
        <BreadcrumbNav links={breadcrumbLinks} />
        {(superAdmin && requests.length > 0) &&
          <MessageBox type="info" message={t("companylist.Approval-requests-waiting")} />
        }
        <Heading mt="10%" as="h1" textStyle="h1" fontWeight={["400"]} mb="3">
          {t('companylist.Welcome') + " " }
          {superAdmin &&
          <Button aria-label={"Export"} onClick={() => handleExport()} variant="ghost">{t("companylist.Export")}</Button>
          }
        </Heading>
        
        <Box as="h2" textStyle="h1" fontWeight="400" fontSize={["16px", "20px", "28px"]} mb={superAdmin ? ["25px", "42px"] : ["4px", "6px"]}>
          {superAdmin ? t('companylist.Search-company-title') : t('companylist.Select-company')}
        </Box> 
        {!superAdmin &&
          <Box as="h2" textStyle="h1" fontWeight="400" fontSize={["14px", "18px", "26px"]} mb={["25px", "42px"]}>
            {t('companylist.Add-company-prefix')+" "}
            {<a href={"activatecompany"} style={{textDecorationLine: 'underline'}}>{t('companylist.From-here')} </a>}
            {" "+t('companylist.Add-company-suffix')}
          </Box> 
        }
        {superAdmin &&
          <SearchInput handleSearch={handleSearch} t={t} keyword={keyword} searchKey={searchKey} />
        }
        {superAdmin && companyErrors &&
          <MessageBox type="error" isOpen={true} errorObject={companyErrors} />
        }
        {(superAdmin && (keyword.keyword || searchKey)) && (
          ((loading || keyword.loading) && keyword.request && keyword.request === "search")
            ? (keyword.keyword) ? <Button isLoading={true} variant="outline"></Button> : ""
            : (companies && companies.length > 0)
              ? <Box>
                {(moreCompanies) &&
                  <MessageBox type="info" isOpen={true} message={t("companylist.Specify-search")} />
                }
                {
                  Object.keys(companies).map(key => (
                    <CompanyListBox key={key} company={companies[key]} superAdmin={superAdmin ? true : false} />
                  ))
                }
              </Box>
              :
              <Heading mt="3" as="h3" textStyle="h3">{t('companylist.No-search-results')}</Heading>
        )
        }
        {(superAdmin && requests) &&
          <ApprovalRequests requests={requests} />
        }
        {!superAdmin && (
          (companies && companies.length > 0)
            ? <Box>
              {
                Object.keys(companies).map(key => (
                  <CompanyListBox key={key} company={companies[key]} />
                ))
              }
            </Box>
            :
            <Heading mt="3" as="h3" textStyle="h3">{!userCompanies.length ? t('companylist.No-user-company-found') : t('companylist.No-company-found')}</Heading>
        )
        }
      </Box >
    </Container >
  );
}


export default CompanyList;
