import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_ADMIN, API_PR, fetchPostAPI } from './API';
import { ACTIVE, DISABLED, FROZEN, WAITING_APPROVAL } from './helpers';


/*const company = {
    id: "",
    businessId: "",
    name: "",
    state: "",
    registerState: "",
    users: [],
    address: {
        streetAddress: "",
        postCode: "",
        postOffice: ""
    }
}*/

/*const user = {
    id: "",
    role: newUser.role,
    phoneNumber: newUser.phoneNumber,
    email: newUser.email,
    state: "ACTIVE",
    company: {
        id: company.id,
        businessId: company.businessId,
        name: company.name,
        state: company.state,
        registerState: company.registerState
    },
    purchaseRight: {
        maxAmount: newUser.purchaseRight.maxAmount,
        currencyCode: newUser.purchaseRight.currencyCode,
        period: newUser.purchaseRight.period
    },
    person: {
        socialSecurityNumber: newUser.person.socialSecurityNumber,
        identifierCountryCode: newUser.person.identifierCountryCode,
        firstNames: newUser.person.firstNames,
        lastName: newUser.person.lastName
    }
};*/



export const selectCompany = createAsyncThunk("companies/selectCompany",
    async ({ businessId }, { rejectWithValue }) => {
        const url = `${API_PR}/company/use`;
        const payload = {
            "businessId": businessId,
        }
        const result = fetchPostAPI(url, payload, rejectWithValue);
        return result;
    }
);

export const saveActor = createAsyncThunk("companies/saveActor",
    async ({ newUser, userId, businessId }, { rejectWithValue }) => {
        const url = `${API_PR}/actor/save`;
        let payload = { ...newUser, id: userId, company: { businessId: businessId } };
        if (userId === "NEW") {
            delete payload.id;
        }
        const result = fetchPostAPI(url, payload, rejectWithValue);
        return result;
    }
);

export const deleteActor = createAsyncThunk("companies/deleteActor",
    async ({ userId, businessId }, { rejectWithValue }) => {
        const url = `${API_PR}/actor/delete`;
        const payload = { "id": userId };
        const result = fetchPostAPI(url, payload, rejectWithValue, "delete");
        return result;
    }
);


export const disableActor = createAsyncThunk("companies/disableActorStatus",
    async ({ userId, businessId }, { rejectWithValue }) => {
        const url = `${API_PR}/actor/disable`;
        const payload = { "id": userId };
        const result = fetchPostAPI(url, payload, rejectWithValue);
        return result;
    }
);

export const activateActor = createAsyncThunk("companies/activateActorStatus",
    async ({ userId, businessId }, { rejectWithValue }) => {
        const url = `${API_PR}/actor/activate`;
        const payload = { "id": userId };
        const result = fetchPostAPI(url, payload, rejectWithValue);
        return result;
    }
);

// superadmin actions
export const exportCsv = createAsyncThunk("companies/exportCsv",
async (_, {rejectWithValue}) => {
    const url = `${API_ADMIN}/companies/exportcsv`;
    const payload = {};
    return fetchPostAPI(url, payload, rejectWithValue, "get", "csv");
}
);

export const saveSuperadminActor = createAsyncThunk("companies/saveActor",
async ({ newUser, userId, businessId }, { rejectWithValue }) => {
    const url = `${API_ADMIN}/superadmin/save`;
    let payload = { ...newUser, id: userId, company: { businessId: businessId } };
    if (userId === "NEW") {
        delete payload.id;
    }
    const result = fetchPostAPI(url, payload, rejectWithValue);
    return result;
}
);

export const deleteSuperadminActor = createAsyncThunk("companies/deleteActor",
async ({ userId, businessId }, { rejectWithValue }) => {
    const url = `${API_ADMIN}/superadmin/delete`;
    const payload = { "id": userId };
    const result = fetchPostAPI(url, payload, rejectWithValue, "delete");
    return result;
}
);

export const activatePendingCompany = createAsyncThunk("companies/activatePendingCompanyStatus",
    async ({ companyId, businessId }, { rejectWithValue }) => {
        const url = `${API_ADMIN}/company/activate_pending`;
        const payload = { "id": companyId };
        const result = fetchPostAPI(url, payload, rejectWithValue);
        return result;
    }
);

export const freezeCompany = createAsyncThunk("companies/freezeCompanyStatus",
    async ({ companyId, businessId }, { rejectWithValue }) => {
        const url = `${API_ADMIN}/company/freeze`;
        const payload = { "id": companyId };
        const result = fetchPostAPI(url, payload, rejectWithValue);
        return result;
    }
);

export const activateCompany = createAsyncThunk("companies/activateCompanyStatus",
    async ({ companyId, businessId }, { rejectWithValue }) => {
        const url = `${API_ADMIN}/company/activate`;
        const payload = { "id": companyId };
        const result = fetchPostAPI(url, payload, rejectWithValue);
        return result;
    }
);

export const searchCompanies = createAsyncThunk("companies/searchCompanies",
    async ({ keyword }, { rejectWithValue }) => {
        const url = `${API_ADMIN}/companies/search/?search-term=${keyword}`;
        const result = fetchPostAPI(url, {}, rejectWithValue, "get");
        return result;
    }
);

export const freezeActor = createAsyncThunk("companies/freezeActorStatus",
    async ({ userId, businessId }, { rejectWithValue }) => {
        const url = `${API_ADMIN}/actor/freeze`;
        const payload = { "id": userId };
        const result = fetchPostAPI(url, payload, rejectWithValue);
        return result;
    }
);

export const defreezeActor = createAsyncThunk("companies/defreezeActorStatus",
    async ({ userId, businessId }, { rejectWithValue }) => {
        const url = `${API_ADMIN}/actor/activate`;
        const payload = { "id": userId };
        const result = fetchPostAPI(url, payload, rejectWithValue);
        return result;
    }
);

const findCompanyIndex = (state, arg) => {
    let businessId = arg.businessId;
    let companyIndex = state.companies.findIndex(item => item.businessId === businessId);
    return companyIndex !== -1 ? companyIndex : null;
}

const findUserIndex = (state, arg, companyIndex) => {
    let userId = arg.userId;
    let userIndex = null;
    if (companyIndex !== null) {
        userIndex = state.companies[companyIndex].users.findIndex(item => item.id === userId);
    }
    return userIndex !== -1 ? userIndex : null;
}

const changeStatusCompany = (state, arg, payload, status, type) => {
    const companyIndex = findCompanyIndex(state, arg);
    if (companyIndex !== null) {
        if (payload.result > 0) {
            state.companies[companyIndex].state = status;
            if (type === "activatePendingCompanyStatus") {
                //activate WAITING_APPROVAL users as well
                return state.companies[companyIndex].users.map(item => {
                    if (item.state === WAITING_APPROVAL) {
                        item.state = ACTIVE;
                    }
                    return state;
                });
            }
        } else {
            state.error = { code: "Action-failed" };
        }
    }
}

const changeStatusUser = (state, arg, payload, status) => {
    const companyIndex = findCompanyIndex(state, arg);
    const userIndex = findUserIndex(state, arg, companyIndex);
    if (userIndex !== null) {
        if (payload.result > 0) {
            state.companies[companyIndex].users[userIndex].state = status;
        } else {
            state.error = { code: "Action-failed" };
        }
    } else {
        state.error = { code: "No-user" };
    }
}

const setPayloadError = (state, payload) => {
    if (payload.error) {
        state.error = payload;
        return true;
    } else {
        return false;
    }
}

function isRejectedAction(action) {
    let suffix = action.type.split("/")[0];
    let middle = action.type.split("/")[1];
    return suffix === "companies" && action.type.endsWith('rejected') && middle !== "exportCsv";
}
function isCsvRejectedAction(action) {
    let suffix = action.type.split("/")[0];
    let middle = action.type.split("/")[1];
    return suffix === "companies" && action.type.endsWith('rejected') && middle === "exportCsv";
}

function isPendingAction(action) {
    let suffix = action.type.split("/")[0];
    return suffix === "companies" && action.type.endsWith('pending');
}

function isActorStatusAction(action) {
    return action.type.endsWith('ActorStatus/fulfilled');
}

function isCompanyStatusAction(action) {
    return action.type.endsWith('CompanyStatus/fulfilled');
}

const companiesSlice = createSlice({
    name: "companies",
    initialState: { loading: "", error: "", more: "", companies: [] },
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(
                selectCompany.fulfilled, (state, action) => {
                    const error = setPayloadError(state, action.payload);
                    if (!error) {
                        const index = findCompanyIndex(state, action.meta.arg) ?? state.companies.length;
                        state.companies[index] = action.payload;
                        state.error = "";
                    }
                    state.loading = "";
                }
            )
            .addCase(
                searchCompanies.fulfilled, (state, action) => {
                    const error = setPayloadError(state, action.payload);
                    if (!error) {
                        state.companies = action.payload.companies ?? [];
                        state.more = action.payload.moreMatchesAvailable;
                        state.searchKey = action.meta.arg.keyword;
                        state.error = "";
                    } else {
                        state.companies = [];
                    }
                    state.loading = "";
                }
            )
            .addCase(
                exportCsv.fulfilled, (state, action) => {
                    const error = setPayloadError(state, action.payload);
                    if (!error) {
                        state.error = "";
                        const link = document.createElement('a');
                        link.href = action.payload.url;

                        link.setAttribute('download', action.payload.filename);
                        document.body.appendChild(link);
                        link.click();
                    }
                    state.loading = "";
                }
            )
            .addCase(
                saveActor.fulfilled, (state, action) => {
                    let arg = action.meta.arg;
                    const error = setPayloadError(state, action.payload);
                    if (!error) {
                        const companyIndex = findCompanyIndex(state, arg);
                        const userIndex = findUserIndex(state, arg, companyIndex) ?? state.companies[companyIndex].users.length;
                        if (action.payload.id) {
                            state.companies[companyIndex].users[userIndex] = { ...action.payload, saved: action.payload.id };
                            state.error = "";
                        } else {
                            state.error = { code: "No-user" };
                        }
                    }
                    state.loading = "";
                }
            )
            .addCase(
                deleteActor.fulfilled, (state, action) => {
                    let arg = action.meta.arg;
                    const error = setPayloadError(state, action.payload);
                    if (!error) {
                        const companyIndex = findCompanyIndex(state, arg);
                        const userIndex = findUserIndex(state, arg, companyIndex);
                        if (userIndex !== null && action.payload.result === "ok") {
                            state.companies[companyIndex].users.splice(userIndex, 1);
                            state.companies[companyIndex].redirect = "redirect";
                            state.error = "";
                        } else {
                            state.error = { code: "No-user" };
                        }
                    }
                    state.loading = "";
                }
            )
            .addMatcher(
                isCompanyStatusAction, (state, action) => {
                    let type = action.type.split("/")[1];
                    const companyStates = { freezeCompanyStatus: FROZEN, activateCompanyStatus: ACTIVE, activatePendingCompanyStatus: ACTIVE };

                    const error = setPayloadError(state, action.payload);
                    if (!error) {
                        changeStatusCompany(state, action.meta.arg, action.payload, companyStates[type], type);
                        state.error = "";
                    }
                    state.loading = "";
                }
            )
            .addMatcher(
                isActorStatusAction, (state, action) => {
                    let type = action.type.split("/")[1];
                    const userStates = { defreezeActorStatus: ACTIVE, activateActorStatus: ACTIVE, freezeActorStatus: FROZEN, disableActorStatus: DISABLED };

                    const error = setPayloadError(state, action.payload);
                    if (!error) {
                        changeStatusUser(state, action.meta.arg, action.payload, userStates[type]);
                        state.error = "";
                    }
                    state.loading = "";
                }
            )
            .addMatcher(
                isRejectedAction, (state, action) => {
                    let { businessId, userId, companyId, keyword } = action.meta.arg;
                    let error = action.payload ?? action.error;
                    state.error = { ...error, businessId: businessId, userId: userId, companyId: companyId, keyword: keyword };
                    state.loading = "";
                }
            )
            .addMatcher(
                isCsvRejectedAction, (state, action) => {
                    state.loading = "";
                }
            )
            .addMatcher(
                isPendingAction, (state) => {
                    state.loading = "loading";
                }
            )
    }
});

const reducer = companiesSlice.reducer;
export default reducer;





