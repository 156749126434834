import React, { Suspense, useEffect } from "react";
import { BrowserRouter, useLocation } from "react-router-dom";
//providers
import { ChakraProvider } from "@chakra-ui/react";
//theme
import theme from "../css/theme.js";
import "../css/style.css";
//components
import Routes from "../components/Routes";



const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const App = () => {

  return (
    <BrowserRouter >
      <ScrollToTop />
      <ChakraProvider theme={theme}>
        <Suspense fallback="loading">
          <Routes />
        </Suspense>
      </ChakraProvider >
    </BrowserRouter>
  );
}

export default App;
